import { Formik, setNestedObjectValues } from 'formik'
import Select, {
  components,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  Props,
  SingleValue,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import React, { useState, MouseEventHandler } from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import {
  DealerHelper,
  httpUserInfo,
  UserHelper,
  ReactSelectOptionType,
  arrayMove,
  isString,
  except,
  isStringEqualCI,
} from '@monorepo/infra'
import { MasterDealerResponse } from '@monorepo/interfaces'
import * as S from './styles'
import './index.css'

export interface IUserForm {
  firstName?: string
  lastName?: string
  email?: string
  userId: string
  dealerTypes?: string[]
  enabled?: boolean
  associatedMasterDealers?: string[]
  groups?: string[]
  principalOf?: string[]
}

const UserSchema = Yup.object().shape({
  dealerTypes: Yup.array().of(Yup.string()).optional(),
  enabled: Yup.boolean().optional(),
  associatedMasterDealers: Yup.array()
    .of(Yup.string())
    .required('Associated master dealer is required')
    .min(1, 'Associated master dealer is required'),
  groups: Yup.array()
    .of(Yup.string())
    .required('Dealer role is required')
    .min(1, 'Dealer role is required'),
  principalOf: Yup.array().of(Yup.string()).optional(),
})

type UserFormProps = {
  close: () => void
  actionForm: (data: IUserForm) => void
  initialValues?: IUserForm
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<ReactSelectOptionType>) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    return <components.MultiValue {...props} innerProps={innerProps} />
  }
)

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
)

const SortableSelect = SortableContainer(AsyncSelect) as React.ComponentClass<
  Props<ReactSelectOptionType, true> & SortableContainerProps
>

const UserForm: React.FC<UserFormProps> = ({
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    dealerTypes: [],
    enabled: false,
    associatedMasterDealers: [],
    groups: [],
    principalOf: undefined,
  },
  actionForm,
  close,
}) => {
  const getInitialDealerTypes = (
    initialDealerTypes?: string[]
  ): ReactSelectOptionType[] => {
    return DealerHelper.dealerTypes.filter(
      (item) =>
        Array.isArray(initialDealerTypes) &&
        initialDealerTypes?.findIndex((dt) => dt === item.value) !== -1
    )
  }

  const mapAssociatedMasterDealersToSelectItemType = (
    initialAssociatedMasterDealers?: string[]
  ): ReactSelectOptionType[] => {
    return Array.isArray(initialAssociatedMasterDealers)
      ? initialAssociatedMasterDealers.map((item) => ({
          value: item,
          label: item,
        }))
      : []
  }

  const activeStr = 'ACTIVE'
  const inactiveStr = 'INACTIVE'
  const getDropdownButtonText = (enabled?: boolean) =>
    enabled ? activeStr : inactiveStr

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownButtonText, setDropdownButtonText] = useState(
    getDropdownButtonText(initialValues.enabled)
  )

  const handleAssociatedMasterDealerSearch = (
    inputValue: string,
    callback: (options: ReactSelectOptionType[]) => void
  ) => {
    if (!callback) {
      return
    }

    if (!inputValue) {
      callback([])
      return
    }

    httpUserInfo
      .get<MasterDealerResponse>({
        url: `/masterdealer/search?masterDealerId=${inputValue}`,
      })
      .then((res) => res?.data)
      .then((res) =>
        callback(
          mapAssociatedMasterDealersToSelectItemType(
            Array.isArray(res?.data)
              ? res.data
                  .filter((item) => !!item.masterDealerId)
                  .map((item) => item.masterDealerId)
              : []
          )
        )
      )
      .catch(() => callback([]))
  }

  const debouncedHandleAssociatedMasterDealerSearch = _.debounce(
    handleAssociatedMasterDealerSearch,
    500
  )

  const handleAssociatedMasterDealersChange = (
    newValue:
      | MultiValue<ReactSelectOptionType>
      | SingleValue<ReactSelectOptionType>,
    values: IUserForm,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const associatedMasterDealers = Array.isArray(newValue)
      ? (newValue as ReactSelectOptionType[])?.map(({ value }) => value)
      : []

    setFieldValue?.('associatedMasterDealers', associatedMasterDealers)

    if (Array.isArray(values.principalOf) && values.principalOf.length) {
      if (associatedMasterDealers.length) {
        if (
          except(values.principalOf, associatedMasterDealers, isStringEqualCI)
            .length
        ) {
          const tmpPrincipalOf = _.cloneDeep(values.principalOf)
          let changed = false

          for (let i = tmpPrincipalOf.length - 1; i >= 0; --i) {
            if (
              associatedMasterDealers.findIndex((item) =>
                isStringEqualCI(item, tmpPrincipalOf[i])
              ) === -1
            ) {
              tmpPrincipalOf.splice(i, 1)
              changed = true
            }
          }

          if (changed) {
            setFieldValue?.('principalOf', tmpPrincipalOf)
          }
        }
      } else {
        setFieldValue?.('principalOf', [])
      }
    }
  }

  const getPrincipalOfOptions = (masterDealerIds?: string[]) =>
    Array.isArray(masterDealerIds)
      ? masterDealerIds
          .filter((item) => isString(item) && item.trim().length)
          .map((item) => ({
            value: item.trim().toLowerCase(),
            label: item.trim().toLowerCase(),
          }))
      : undefined

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false)
        actionForm(values)
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateForm,
        submitForm,
        setTouched,
        errors,
      }) => (
        <S.DialogForm>
          <S.Header>
            <S.Title>
              <S.TitleText>Edit User</S.TitleText>
            </S.Title>
            <S.Dropdown>
              <S.DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
                <S.DropdownButtonText data-open={dropdownOpen}>
                  {dropdownButtonText}
                </S.DropdownButtonText>
              </S.DropdownButton>
              <S.DropdownContent data-open={dropdownOpen}>
                <RadioGroup
                  name="enabled"
                  value={values.enabled}
                  onBlur={() => setFieldTouched('enabled')}
                  onChange={(e, value) => {
                    setFieldValue('enabled', value === 'true')
                    setDropdownButtonText(
                      getDropdownButtonText(value === 'true')
                    )
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio size="small" />}
                    label={activeStr}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio size="small" />}
                    label={inactiveStr}
                  />
                </RadioGroup>
              </S.DropdownContent>
            </S.Dropdown>
          </S.Header>
          <S.InputTitle>Dealer type(s)</S.InputTitle>
          <Select
            name="dealerTypes"
            placeholder="Dealer type(s)"
            isMulti
            options={DealerHelper.dealerTypes}
            value={getInitialDealerTypes(values.dealerTypes)}
            onBlur={() => setFieldTouched('dealerTypes')}
            onChange={(newValue) =>
              setFieldValue(
                'dealerTypes',
                newValue?.map((item) => item.value)
              )
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          />
          <S.InputTitle>Dealer role</S.InputTitle>
          <Select
            name="groups"
            placeholder="Dealer role(s)"
            isMulti
            options={UserHelper.userGroupsOptions}
            value={UserHelper.getUserGroupsOptions(values.groups)}
            onBlur={() => setFieldTouched('groups')}
            onChange={(newValue) =>
              setFieldValue(
                'groups',
                newValue?.map((item) => item.value)
              )
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          />
          <S.InputTitle>Associated master dealer ID</S.InputTitle>
          <SortableSelect
            useDragHandle
            axis="xy"
            onSortEnd={({ oldIndex, newIndex }) => {
              const newValue = arrayMove(
                values.associatedMasterDealers as string[],
                oldIndex,
                newIndex
              )
              setFieldValue('associatedMasterDealers', newValue)
            }}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            name="associatedMasterDealers"
            placeholder="Search associated master dealer ID..."
            isMulti
            options={[]}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              // @ts-ignore We're failing to provide a required index prop to SortableElement
              MultiValue: SortableMultiValue,
              // @ts-ignore We're failing to provide a required index prop to SortableElement
              MultiValueLabel: SortableMultiValueLabel,
            }}
            value={mapAssociatedMasterDealersToSelectItemType(
              values.associatedMasterDealers
            )}
            onBlur={() => setFieldTouched('associatedMasterDealers')}
            onChange={(newValue) =>
              handleAssociatedMasterDealersChange(
                newValue,
                values,
                setFieldValue
              )
            }
            isClearable={true}
            isSearchable={true}
            loadOptions={debouncedHandleAssociatedMasterDealerSearch}
            hideSortableGhost={false}
            styles={S.AssociatedMasterDealersMultiSelectStyle}
            helperClass="sortableHelper"
          />
          {!!errors?.associatedMasterDealers ? (
            <S.ErrorMessage>{errors?.associatedMasterDealers}</S.ErrorMessage>
          ) : undefined}
          <S.InputTitle>Principal</S.InputTitle>
          <Select
            name="principalOf"
            placeholder="Principal"
            isMulti
            options={getPrincipalOfOptions(values.associatedMasterDealers)}
            value={getPrincipalOfOptions(values.principalOf)}
            onBlur={() => setFieldTouched('principalOf')}
            onChange={(newValue) =>
              setFieldValue(
                'principalOf',
                newValue?.map((item) => item.value)
              )
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          />
          <S.DialogActions>
            <S.ModalButton
              colorOption="stroke"
              label="CANCEL"
              onClick={() => close()}
            />
            <S.ModalButton
              colorOption="black"
              label="SAVE"
              onClick={() => {
                validateForm().then((errors) => {
                  setTouched(setNestedObjectValues(errors, true))
                })
                submitForm()
              }}
            />
          </S.DialogActions>
        </S.DialogForm>
      )}
    </Formik>
  )
}

export { UserForm }
