import * as S from './styles'

import { Event, ProductStatus } from '@monorepo/interfaces'
import { EventSchema, initialValues } from './ManageFormHelpers'
import React, { useCallback, useEffect, useState } from 'react'
import { dealerChannels, productStatus, vendors } from '../../../../database'
import {
  http,
  usStates as usStatesList,
  EventHelper,
  isObject,
  formatCurrency,
} from '@monorepo/infra'
import { useHistory, useParams } from 'react-router-dom'

import CreatableSelect from 'react-select/creatable'
import { ManageCard, TimeLineList } from '../../../organisms'
import { ManageTemplate } from '../..'
import { PageSpinner } from '../../..'
import Select from 'react-select'
import { colors } from '@monorepo/theme'
import { useFormik } from 'formik'
import { DealerHelper } from '@monorepo/infra'
import cloneDeep from 'lodash/cloneDeep'

export enum ManageEventsEditingMode {
  CREATING = 0,
  EDITING,
}

const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
}

export interface ManageEventsProps {
  editingMode: ManageEventsEditingMode
}

type SelectItemType = {
  value: string
  label: string
}

type EventType = Omit<
  Event,
  'createdBy' | 'createdOn' | 'updatedBy' | 'updatedOn'
> & {
  state: SelectItemType | string | null
  type: SelectItemType | number | null
  vendor: SelectItemType | number | null
}

const usStates = usStatesList.map(({ name: label, value }) => ({
  label,
  value,
}))

const ManageEvents: React.FC<ManageEventsProps> = ({ editingMode }) => {
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )

  const eventTypes = EventHelper.getEventTypeOptions()

  const eligibleDealerTypes = ['top_event_dealer', 'heb_dealer']
  const dealerTypes = DealerHelper.dealerTypes.filter((item) =>
    eligibleDealerTypes.find((dt) => dt === item.value)
  )

  const getInitialDealerTypes = (
    initialDealerTypes?: string[]
  ): { value: string; label: string }[] => {
    return dealerTypes.filter(
      (item) =>
        Array.isArray(initialDealerTypes) &&
        initialDealerTypes?.findIndex((dt) => dt === item.value) !== -1
    )
  }

  const [event, setEvent] = useState<EventType>(initialValues)

  const history = useHistory()
  const { id: eventId } = useParams<{ id: string }>()

  const handleGoBack = useCallback(() => {
    history.push('/events')
  }, [history])

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: EventSchema,
    initialValues: event,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
  })

  const handleCreateEvent = useCallback(
    (values: EventType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .post({ url: `/event`, data: values })
        .then((response) => {
          const event = response?.data as Event

          history.push(`/events/edit/${event?._id}`)
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    [history]
  )

  const handleDuplicateEvent = async (event: EventType) => {
    setLoadingStatus(LoadingStatus.SAVING)

    const clonedEvent = cloneDeep(event)

    delete clonedEvent._id
    clonedEvent.title = `Cloned ${clonedEvent.title}`
    clonedEvent.status = ProductStatus.DRAFT
    clonedEvent.sku = `cloned${new Date().getTime().toString()}`

    try {
      const response = await http.post({ url: `/event`, data: clonedEvent })
      const createdEvent = response?.data as EventType
      setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
      history.push(`/events/edit/${createdEvent?._id}`)
      window.location.reload()
    } catch (error) {
      console.error(error)
      setLoadingStatus(LoadingStatus.SAVING_ERROR)
      throw error
    }
  }

  const handleEditEvent = useCallback(
    (values: EventType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({ url: `/event/${eventId}`, data: values })
        .then((data) => {
          setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
          setEvent(data.data as EventType)
          formik.resetForm({ values })
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    [eventId, formik]
  )

  const handleSubmit = useCallback(
    (values: EventType) => {
      values.price = formatCurrency(values.price)
      values.state = isObject(values.state)
        ? (values.state as SelectItemType).label
        : values.state
      if (values.channels && values.channels.length > 0) {
        values.channels = values.channels.map((channel) => {
          return channel.toLowerCase()
        })
      }

      if (values.endDate === null) {
        values.endDate = values.startDate
      }

      if (editingMode === ManageEventsEditingMode.CREATING) {
        handleCreateEvent(values)
        return
      }

      if (editingMode === ManageEventsEditingMode.EDITING) {
        handleEditEvent(values)
        return
      }
    },
    [editingMode, handleCreateEvent, handleEditEvent]
  )

  const handleSaveDraft = useCallback(
    (values: EventType) => {
      handleSubmit(values)
    },
    [handleSubmit]
  )

  const handleDiscardChanges = useCallback(() => {
    formik.setValues(event)
  }, [event, formik])

  useEffect(() => {
    const isEditing = eventId && editingMode === ManageEventsEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchEvent = isEditing && isInitializing

    if (!shouldSearchEvent) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `/event/${eventId}` })
      .then((response) => {
        const event = response?.data as Event
        event.price = formatCurrency(event.price)
        event.channels = event?.channels?.map((item) => item.toLowerCase())

        setEvent(event)
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
  }, [editingMode, eventId, formik, loadingStatus])

  return (
    <ManageTemplate
      onGoBack={handleGoBack}
      backText="Back to all Events"
      titleText={`${
        editingMode === ManageEventsEditingMode.CREATING ? 'Create' : 'Manage'
      } Event`}
      headerRightSideChildren={
        <S.ButtonsContainer>
          {!formik.dirty && formik.isValid && (
            <S.FormButton onClick={() => handleDuplicateEvent(formik.values)}>
              Duplicate
            </S.FormButton>
          )}
          {formik.dirty && (
            <>
              <S.FormButton onClick={handleDiscardChanges}>
                Discard
              </S.FormButton>
              {formik.isValid && (
                <>
                  {editingMode === ManageEventsEditingMode.CREATING && (
                    <S.FormButton
                      backgroundColor={colors.mediumGrey}
                      onClick={() => {
                        const draftStatus = productStatus.find(
                          ({ value }) =>
                            value === ProductStatus.DRAFT.toString()
                        )
                        formik.setFieldValue(
                          'status',
                          parseInt(String(draftStatus?.value))
                        )

                        handleSaveDraft({
                          ...formik.values,
                          status: parseInt(ProductStatus.DRAFT.toString()),
                        })
                      }}
                    >
                      Save as Draft
                    </S.FormButton>
                  )}
                  <S.FormButton
                    backgroundColor={colors.blue}
                    onClick={() => handleSubmit(formik.values)}
                  >
                    Save
                  </S.FormButton>
                </>
              )}
            </>
          )}
        </S.ButtonsContainer>
      }
    >
      {[LoadingStatus.FETCHING, LoadingStatus.SAVING].includes(
        loadingStatus
      ) ? (
        <PageSpinner />
      ) : (
        <S.ColumnsContainer>
          <S.LeftColumn>
            <ManageCard title="Title">
              <S.TextInput
                name="title"
                label="Title"
                value={formik.values.title}
                onBlur={handleBlur}
                invalid={!!errors.title && touched.title}
                invalidMessage={errors.title}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('title', value)
                }
              />
            </ManageCard>
            <ManageCard title="Location">
              <S.LocationContentContainer>
                <S.TextInput
                  name="city"
                  label="City"
                  onBlur={handleBlur}
                  invalid={!!errors.city && touched.city}
                  invalidMessage={errors.city}
                  value={formik.values.city}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('city', value)
                  }
                />
                <Select
                  name="state"
                  placeholder="State"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={usStates}
                  value={
                    typeof formik.values.state === 'string'
                      ? usStates.find(
                          ({ value, label }) =>
                            value === formik.values.state ||
                            label === formik.values.state
                        )
                      : formik.values.state
                  }
                  onChange={(newValue) =>
                    formik.setFieldValue('state', newValue)
                  }
                  styles={S.SelectStyle}
                />
                <S.TextInput
                  name="zipCode"
                  label="Zip Code"
                  onBlur={handleBlur}
                  invalid={!!errors.zipCode && touched.zipCode}
                  invalidMessage={errors.zipCode}
                  value={formik.values.zipCode}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('zipCode', value)
                  }
                />
              </S.LocationContentContainer>
            </ManageCard>
            <S.EventDataContainer>
              <ManageCard title="Event Date">
                <S.EventDatePickersContainer>
                  <S.StartEndDateContainer>
                    <S.EventDateLabel>Start Date</S.EventDateLabel>
                    <S.KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Select a date"
                      format="MM/dd/yy"
                      disableToolbar
                      name="startDate"
                      onBlur={handleBlur}
                      error={!!errors.startDate && touched.startDate}
                      value={formik.values.startDate}
                      onChange={(date) => {
                        formik.setFieldValue('startDate', date)

                        if (formik.values.endDate === null) {
                          formik.setFieldValue('endDate', date)
                        }
                      }}
                    />
                  </S.StartEndDateContainer>
                  <S.DateRangeDelimiter />
                  <S.StartEndDateContainer>
                    <S.EventDateLabel>End Date</S.EventDateLabel>
                    <S.KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Select a date"
                      format="MM/dd/yy"
                      disableToolbar
                      minDate={formik.values.startDate}
                      value={formik.values.endDate}
                      onChange={(date) => formik.setFieldValue('endDate', date)}
                    />
                  </S.StartEndDateContainer>
                  <S.EventDateClearContainer>
                    <S.ClearButton
                      colorOption="delicateGrey"
                      icon="Close"
                      borderRadius
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('startDate', null)
                        formik.setFieldValue('endDate', null)
                      }}
                    />
                  </S.EventDateClearContainer>
                </S.EventDatePickersContainer>
              </ManageCard>
              <ManageCard title="Event Type">
                <Select
                  name="type"
                  placeholder=""
                  options={eventTypes.map(({ label, value }) => ({
                    label,
                    value: value.toString(),
                  }))}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.type === 'number'
                      ? eventTypes.find(
                          ({ value }) => value === String(formik.values.type)
                        )
                      : formik.values.type
                  }
                  onChange={(newValue) => {
                    const type = (newValue as SelectItemType)?.value as string

                    formik.setFieldValue('type', parseInt(type))
                  }}
                  styles={S.SelectStyle}
                />
              </ManageCard>
            </S.EventDataContainer>
            <ManageCard title="Image and Thumbnail"></ManageCard>
            <ManageCard title="Description">
              <S.TextInput
                name="description"
                label="Enter a description for this event"
                textarea
                onBlur={handleBlur}
                invalid={!!errors.description && touched.description}
                invalidMessage={errors.description}
                value={formik.values.description}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('description', value)
                }
              />
            </ManageCard>
            <ManageCard title="SKU">
              <S.TextInput
                name="sku"
                label="SKU"
                onBlur={handleBlur}
                invalid={!!errors.sku && touched.sku}
                invalidMessage={errors.sku}
                value={formik.values.sku}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('sku', value)
                }
              />
            </ManageCard>
            <ManageCard title="Price">
              <S.TextInput
                name="price"
                label="$0.00"
                onBlur={handleBlur}
                invalid={!!errors.price && touched.price}
                invalidMessage={errors.price}
                value={formik.values.price}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('price', formatCurrency(value))
                }
              />
            </ManageCard>
            <ManageCard title="Event Timeline">
              <TimeLineList history={event.history} />
            </ManageCard>
          </S.LeftColumn>
          <S.RightColumn>
            <ManageCard title="Product Status">
              <Select
                name="status"
                placeholder=""
                options={productStatus}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={
                  typeof formik.values.status === 'number'
                    ? productStatus.find(
                        ({ value }) => value === String(formik.values.status)
                      )
                    : formik.values.status
                }
                onChange={(newValue) => {
                  const status = (newValue as SelectItemType)?.value as string

                  formik.setFieldValue('status', parseInt(status))
                }}
                styles={S.SelectStyle}
              />
            </ManageCard>
            <ManageCard title="Events Life">
              <S.ProductLifeLabel>
                Activation Date (optional)
              </S.ProductLifeLabel>
              <S.DatePickerContainer>
                <S.KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Select a date"
                  format="MM/dd/yy"
                  disableToolbar
                  value={formik.values.activationDate ?? null}
                  onChange={(date) =>
                    formik.setFieldValue('activationDate', date)
                  }
                />
                <S.ClearButton
                  colorOption="delicateGrey"
                  icon="Close"
                  borderRadius
                  size="small"
                  onClick={() => {
                    formik.setFieldValue('activationDate', null)
                  }}
                />
              </S.DatePickerContainer>
              <S.ProductLifeLabel>
                Expiration Date (optional)
              </S.ProductLifeLabel>
              <S.DatePickerContainer>
                <S.KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Select a date"
                  format="MM/dd/yy"
                  disableToolbar
                  minDate={formik.values.activationDate}
                  value={formik.values.expirationDate ?? null}
                  onChange={(date) =>
                    formik.setFieldValue('expirationDate', date)
                  }
                />
                <S.ClearButton
                  colorOption="delicateGrey"
                  icon="Close"
                  borderRadius
                  size="small"
                  onClick={() => {
                    formik.setFieldValue('expirationDate', null)
                  }}
                />
              </S.DatePickerContainer>
            </ManageCard>
            <ManageCard title="Organization (Admin view only)">
              <S.InputTitle>Vendor</S.InputTitle>
              <Select
                name="vendor"
                placeholder=""
                options={vendors}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={
                  typeof formik.values.vendor === 'number'
                    ? vendors.find(
                        ({ value }) => value === String(formik.values.vendor)
                      )
                    : formik.values.vendor
                }
                onChange={(newValue) => {
                  const vendor = (newValue as SelectItemType)?.value as string

                  formik.setFieldValue('vendor', parseInt(vendor))
                }}
                styles={S.SelectStyle}
              />
            </ManageCard>
            <ManageCard title="Classification">
              <S.InputTitle>Channels</S.InputTitle>
              <Select
                name="channels"
                placeholder={dealerChannels[1].label}
                isMulti
                options={dealerChannels}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={dealerChannels.filter(({ value }) =>
                  formik.values.channels?.includes(value)
                )}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'channels',
                    newValue.map(({ value }) => value)
                  )
                }
                styles={S.MultiSelectStyle}
              />
              <S.InputTitle>Categories</S.InputTitle>
              <CreatableSelect
                name="categories"
                placeholder="DIRECTV, AT&T"
                isMulti
                options={[]}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                value={formik.values.categories.map((category) => ({
                  value: category,
                  label: category,
                }))}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'categories',
                    newValue.map(({ value }) => value)
                  )
                }
                styles={S.MultiSelectStyle}
              />
              <S.InputTitle>Tags</S.InputTitle>
              <CreatableSelect
                name="tags"
                placeholder="Bestseller, Featured"
                isMulti
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                value={formik.values.tags.map((tag) => ({
                  value: tag,
                  label: tag,
                }))}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'tags',
                    newValue.map(({ value }) => value)
                  )
                }
                styles={S.MultiSelectStyle}
              />
              <S.InputTitle>Dealer Type</S.InputTitle>
              <Select
                name="dealerTypes"
                placeholder="Dealer type(s)"
                isMulti
                options={dealerTypes}
                value={getInitialDealerTypes(formik.values.dealerTypes)}
                onBlur={() => formik.setFieldTouched('dealerTypes')}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'dealerTypes',
                    newValue?.map((item) => item.value)
                  )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={S.MultiSelectStyle}
              />
            </ManageCard>
            <ManageCard title="ACB Media ID">
              <S.TextInput
                name="mediaIdACB"
                label="ACB Media ID"
                onBlur={handleBlur}
                invalid={!!errors.mediaIdACB && touched.mediaIdACB}
                invalidMessage={errors.mediaIdACB}
                value={formik.values.mediaIdACB}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('mediaIdACB', value)
                }
              />
            </ManageCard>
            <ManageCard title="Search Terms">
              <CreatableSelect
                name="searchTerms"
                placeholder="Rodeo, Festival, Music"
                isMulti
                value={formik.values.searchTerms?.map((searchTerm) => ({
                  value: searchTerm,
                  label: searchTerm,
                }))}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'searchTerms',
                    newValue.map(({ value }) => value)
                  )
                }
                styles={S.MultiSelectStyle}
              />
            </ManageCard>
            <ManageCard title="Notes">
              <S.TextInput
                name="notes"
                label="Notes"
                value={formik.values.notes}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('notes', value)
                }
              />
            </ManageCard>
          </S.RightColumn>
        </S.ColumnsContainer>
      )}
    </ManageTemplate>
  )
}

export default ManageEvents
