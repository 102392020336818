export var EventStatus;
(function (EventStatus) {
    EventStatus[EventStatus["ACTIVE"] = 1] = "ACTIVE";
    EventStatus[EventStatus["DRAFT"] = 2] = "DRAFT";
    EventStatus[EventStatus["DELETED"] = 3] = "DELETED";
    EventStatus[EventStatus["BOOKED"] = 4] = "BOOKED";
    EventStatus[EventStatus["HIDDEN"] = 8] = "HIDDEN";
})(EventStatus || (EventStatus = {}));
export var EventType;
(function (EventType) {
    EventType[EventType["FAIR_AND_FESTIVAL"] = 1] = "FAIR_AND_FESTIVAL";
    EventType[EventType["KIOSK"] = 2] = "KIOSK";
    EventType[EventType["GROCERY"] = 3] = "GROCERY";
    EventType[EventType["AUTO_SHOW"] = 4] = "AUTO_SHOW";
    EventType[EventType["HOME_AND_GARDEN"] = 5] = "HOME_AND_GARDEN";
    EventType[EventType["TRADE_SHOW"] = 6] = "TRADE_SHOW";
    EventType[EventType["BRIDAL_EXPOS"] = 7] = "BRIDAL_EXPOS";
    EventType[EventType["MUSIC_AND_ARTS"] = 8] = "MUSIC_AND_ARTS";
    EventType[EventType["MDU"] = 9] = "MDU";
    EventType[EventType["STOCK_SHOW_AND_RODEO"] = 10] = "STOCK_SHOW_AND_RODEO";
    EventType[EventType["FARM_AND_AGRICULTURE"] = 11] = "FARM_AND_AGRICULTURE";
    EventType[EventType["SPONSORSHIPS"] = 12] = "SPONSORSHIPS";
    EventType[EventType["FOOD_BEVERAGE"] = 13] = "FOOD_BEVERAGE";
    EventType[EventType["STATE_FAIRS"] = 14] = "STATE_FAIRS";
    EventType[EventType["HEB_EVENTS"] = 15] = "HEB_EVENTS";
    EventType[EventType["SUPERMARKETS"] = 16] = "SUPERMARKETS";
    EventType[EventType["MISCELLANEOUS"] = 17] = "MISCELLANEOUS";
})(EventType || (EventType = {}));
