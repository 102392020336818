import { OperationResult } from '@monorepo/components/src/models/operation-result'
import { httpUserInfo } from '@monorepo/infra'
import { SentSharedFile } from '../models/file-transfer/sent-shared-file'
import { ReceivedSharedFile } from '../models/file-transfer/received-shared-file'
import { PagedResult } from '../models/paged-result'
import { SendSharedFile } from '../models/file-transfer/send-shared-file'
import { UnreadSharedFile } from '../models/file-transfer/unread-shared-file'

const allowedUserGroups = [
  'eventVendorAdmin',
  'productVendorAdmin',
  'directvAdmin',
  'superAdmin',
  'vendorAdmin',
]

const allowedUserGroupsRegexes = allowedUserGroups.map(
  (x) => new RegExp(`^\\/?${x}$`, 'i')
)

export const SharedFileExpirationDays = 60

export const isSharedFileExpired = (nowDaysDiff: number) => {
  return nowDaysDiff > SharedFileExpirationDays
}

export const getSentSharedFilesPageAsync = async (
  page: number,
  pageSize: number
): Promise<OperationResult<PagedResult<SentSharedFile>, string>> => {
  try {
    const response = await httpUserInfo.get<PagedResult<SentSharedFile>>({
      url: '/file-transfer/sent',
      params: {
        page,
        pageSize,
      },
    })

    return !response?.data
      ? {
          kind: 'error',
          errorData: 'Error',
        }
      : {
          kind: 'success',
          data: response.data,
        }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const getReceivedSharedFilesPageAsync = async (
  page: number,
  pageSize: number
): Promise<OperationResult<PagedResult<ReceivedSharedFile>, string>> => {
  try {
    const response = await httpUserInfo.get<PagedResult<ReceivedSharedFile>>({
      url: '/file-transfer/received',
      params: {
        page,
        pageSize,
      },
    })

    return !response?.data
      ? {
          kind: 'error',
          errorData: 'Error',
        }
      : {
          kind: 'success',
          data: response.data,
        }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const getUnreadSharedFilesPageAsync = async (
  page: number,
  pageSize: number
): Promise<OperationResult<PagedResult<UnreadSharedFile>, string>> => {
  try {
    const response = await httpUserInfo.get<PagedResult<UnreadSharedFile>>({
      url: '/file-transfer/unread',
      params: {
        page,
        pageSize,
      },
    })

    return !response?.data
      ? {
          kind: 'error',
          errorData: 'Error',
        }
      : {
          kind: 'success',
          data: response.data,
        }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const tryGetSentSharedFileAsync = async (
  blobFileName: string
): Promise<OperationResult<SentSharedFile | null, string>> => {
  try {
    const response = await httpUserInfo.get<SentSharedFile | null>({
      url: `/file-transfer/sent/${blobFileName}`,
    })

    if (response.status === 200) {
      return {
        kind: 'success',
        data: response.data,
      }
    }

    if (response.status === 404) {
      return {
        kind: 'success',
        data: null,
      }
    }

    return {
      kind: 'error',
      errorData: 'Error',
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const tryGetReceivedSharedFileAsync = async (
  blobFileName: string
): Promise<OperationResult<ReceivedSharedFile | null, string>> => {
  try {
    const response = await httpUserInfo.get<ReceivedSharedFile | null>({
      url: `/file-transfer/received/${blobFileName}`,
    })

    if (response.status === 200) {
      return {
        kind: 'success',
        data: response.data,
      }
    }

    if (response.status === 404) {
      return {
        kind: 'success',
        data: null,
      }
    }

    return {
      kind: 'error',
      errorData: 'Error',
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const sendSharedFileAsync = async (
  sendSharedFile: SendSharedFile,
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<OperationResult<boolean, string>> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('sendSharedFile', JSON.stringify(sendSharedFile))

  try {
    const response = await httpUserInfo.post({
      url: '/file-transfer/send',
      data: formData,
      onUploadProgress: onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.status === 201
      ? {
          kind: 'success',
          data: true,
        }
      : {
          kind: 'error',
          errorData: 'Error',
        }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

export const tryDownloadSharedFileAsync = async (
  blobFileName: string,
  onDownloadProgress?: (progressEvent: ProgressEvent) => void
): Promise<Blob | null> => {
  try {
    const response = await httpUserInfo.get<Blob>({
      url: `/file-transfer/download/${blobFileName}`,
      responseType: 'blob',
      onDownloadProgress: onDownloadProgress,
    })

    return response.status === 200 ? response.data : null
  } catch (error) {
    console.error(error)
    return null
  }
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  try {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  } catch (error) {
    return (bytes / k).toFixed(dm)
  }
}

export const tryMarkAsReadAsync = async (blobFileName: string) => {
  try {
    const response = await httpUserInfo.get({
      url: `/file-transfer/read/${blobFileName}`,
    })

    return response.status === 200 ? true : null
  } catch (error) {
    console.error(error)
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAllowedUser = (user?: any): boolean => {
  if (!Array.isArray(user?.groups)) {
    return false
  }

  try {
    const res: boolean = user.groups.some((x: string) =>
      allowedUserGroupsRegexes.some((y) => y.test(x))
    )
    return res
  } catch (error) {
    return false
  }
}

export const FileTransferHelper = {
  SharedFileExpirationDays,
  getSentSharedFilesPageAsync,
  getReceivedSharedFilesPageAsync,
  getUnreadSharedFilesPageAsync,
  tryGetSentSharedFileAsync,
  tryGetReceivedSharedFileAsync,
  sendSharedFileAsync,
  tryDownloadSharedFileAsync,
  tryMarkAsReadAsync,
  formatBytes,
  isSharedFileExpired,
  isAllowedUser,
}
