import * as S from './styles'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { SharedFileInfoViewModel } from '../../../../view_models/file-transfer/shared-file-info-view-model'
import { FileTransferHelper } from '../../../../helpers/file-transfer-helper'
import ViewSharedFileInfoComponent from '../ViewSharedFileInfoComponent'
import { OperationResult } from '@monorepo/components/src/models/operation-result'
import OperationHelper from '../../../../helpers/operation-result'
import { OperationState } from '../../../../view_models/operation-state'
import { Spinner } from '@monorepo/components'

interface ViewReceivedSharedFileComponentProps {
  blobFileName: string
}

const getSharedFileInfoViewModelAsync = async (
  blobFileName: string
): Promise<OperationResult<SharedFileInfoViewModel | null, string>> => {
  const receivedSharedFile =
    await FileTransferHelper.tryGetReceivedSharedFileAsync(blobFileName)

  if (receivedSharedFile.kind === 'error') {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }

  if (receivedSharedFile.data === null) {
    return {
      kind: 'success',
      data: null,
    }
  }

  if (!receivedSharedFile.data.isRead) {
    FileTransferHelper.tryMarkAsReadAsync(blobFileName).catch((error) =>
      console.error(`Mark as read failed:`, error)
    )
  }

  const sharedFileInfoViewModel: SharedFileInfoViewModel = {
    title: `${receivedSharedFile.data.author.name} sent you a file`,
    subject: receivedSharedFile.data.subject,
    transferInfoTitle: `Sent by`,
    transferInfoContent: receivedSharedFile.data.author.email,
    fileName: receivedSharedFile.data.fileName,
    fileSize: receivedSharedFile.data.fileSize,
    uploadDate: moment(receivedSharedFile.data.uploadDate).toDate(),
    blobFileName: receivedSharedFile.data.blobFileName,
  }

  return {
    kind: 'success',
    data: sharedFileInfoViewModel,
  }
}

const loadSharedFileInfoViewModelAsync = async (
  blobFileName: string,
  setSharedFileInfoViewModelRequestState: (
    sharedFileInfoViewModelRequestState: OperationState<
      SharedFileInfoViewModel | null,
      string
    >
  ) => void
) => {
  await OperationHelper.handleRequestAsync(
    () => getSharedFileInfoViewModelAsync(blobFileName),
    setSharedFileInfoViewModelRequestState,
    'Error',
    (_) => 'Error'
  )
}

const ViewReceivedSharedFileComponent: React.FC<ViewReceivedSharedFileComponentProps> =
  (props) => {
    const [
      sharedFileInfoViewModelRequestState,
      setSharedFileInfoViewModelRequestState,
    ] = useState<OperationState<SharedFileInfoViewModel | null, string>>({
      kind: 'loading',
    })

    useEffect(() => {
      loadSharedFileInfoViewModelAsync(
        props.blobFileName,
        setSharedFileInfoViewModelRequestState
      )
    }, [])

    return (
      <>
        <S.MainBox>
          {sharedFileInfoViewModelRequestState.kind === 'loading' && (
            <Spinner />
          )}
          {sharedFileInfoViewModelRequestState.kind === 'error' && (
            <div>{sharedFileInfoViewModelRequestState.error}</div>
          )}
          {sharedFileInfoViewModelRequestState.kind === 'success' &&
            sharedFileInfoViewModelRequestState.data !== null && (
              <ViewSharedFileInfoComponent
                fileKind="RECEIVED"
                sharedFileInfoViewModel={
                  sharedFileInfoViewModelRequestState.data
                }
              />
            )}
        </S.MainBox>
      </>
    )
  }

export default ViewReceivedSharedFileComponent
