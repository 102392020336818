import { OperationResult } from '@monorepo/components/src/models/operation-result'
import { MediaTileConfig } from '../models/media-tile-config/media-tile-config'
import { http } from '@monorepo/infra'
import { MediaTileConfigProduct } from '../models/media-tile-config/media-tile-config-product'

const allowedUserGroups = [
  'eventVendorAdmin',
  'productVendorAdmin',
  'directvAdmin',
  'superAdmin',
  'vendorAdmin',
]

const allowedUserGroupsRegexes = allowedUserGroups.map(
  (x) => new RegExp(`^\\/?${x}$`, 'i')
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAllowedUser = (user?: any): boolean => {
  if (!Array.isArray(user?.groups)) {
    return false
  }

  try {
    const res: boolean = user.groups.some((x: string) =>
      allowedUserGroupsRegexes.some((y) => y.test(x))
    )
    return res
  } catch (error) {
    return false
  }
}

export const getChannelMediaTileConfigsAsync = async (
  channel: string
): Promise<OperationResult<MediaTileConfig[], string>> => {
  try {
    const res = await http.get<MediaTileConfig[]>({
      url: `/media-tile-config/by-channel/${channel.toLowerCase()}`,
    })

    if (res?.status !== 200) {
      return {
        kind: 'error',
        errorData: 'Failed',
      }
    }

    return {
      kind: 'success',
      data: res.data ?? [],
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: `Error ${error}`,
    }
  }
}

export const searchFirstBatchMediaTileConfigProductsAsync = async (
  search: string
): Promise<OperationResult<MediaTileConfigProduct[], string>> => {
  try {
    const res = await http.get<MediaTileConfigProduct[]>({
      url: `/media-tile-config/product-batch`,
      params: { search },
    })

    if (res?.status !== 200) {
      return {
        kind: 'error',
        errorData: 'Failed',
      }
    }

    return {
      kind: 'success',
      data: res.data ?? [],
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: `Error ${error}`,
    }
  }
}

export const updateMediaTileConfigProductIdAsync = async (
  mediaTileConfigId: string,
  newProductId: string
): Promise<OperationResult<MediaTileConfig, string>> => {
  try {
    const res = await http.patch<MediaTileConfig>({
      url: `/media-tile-config/${mediaTileConfigId}/update-product-id/${newProductId}`,
    })

    if (res?.status !== 200) {
      return {
        kind: 'error',
        errorData: 'Failed',
      }
    }

    return {
      kind: 'success',
      data: res.data,
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: `Error ${error}`,
    }
  }
}

export const MediaTileConfigHelper = {
  getChannelMediaTileConfigsAsync,
  searchFirstBatchMediaTileConfigProductsAsync,
  updateMediaTileConfigProductIdAsync,
  isAllowedUser,
}
