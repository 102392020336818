import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionFileTransfer: React.FC = () => {
  const { t } = useTranslation('admin')

  return (
    <S.Container>
      <S.FileTransfer>{t('section_filetransfer.title')}</S.FileTransfer>
    </S.Container>
  )
}

export default SectionFileTransfer
