import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = MuiBox

export const RowBox = styled(Box)<{isEmphasized: boolean}>`
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  gap: 1rem;
  padding: 0.5rem;
  background: white;
  ${(props) => props.isEmphasized && 'font-weight: 500;'}
`

export const CellBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0.1rem;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EmphasizeIconBox = styled(CellBox)`
  justify-content: center;
  width: 1rem;
`

export const TitleBox = styled(CellBox)`
  width: 21rem;
`

export const MessageBox = styled(CellBox)`
  flex-grow: 1;
  flex-shrink: 1;
`

export const DateBox = styled(CellBox)<{isEmphasized: boolean}>`
  color: ${(props) => props.isEmphasized ? '#000000' : '#707D8D' };
  width: 14rem;
`

export const ViewBox = styled(CellBox)``
