import { useCallback, useMemo } from 'react'
import * as S from './styles'
import { ActionButton } from '../../../atoms'
import { Icon } from '@monorepo/components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { SharedFileInfoRowViewModel } from '../../../../view_models/file-transfer/shared-file-info-row-view-model'
import { FileTransferHelper } from '../../../../helpers/file-transfer-helper'

interface SharedFileInfoRowComponentProps {
  sharedFileInfoRowViewModel: SharedFileInfoRowViewModel
}

const SharedFileInfoRowComponent: React.FC<SharedFileInfoRowComponentProps> = (
  props
) => {
  const history = useHistory()

  const { dateText, isExpired } = useMemo(() => {
    const now = moment.utc()
    const momentDate = moment(props.sharedFileInfoRowViewModel.date)
    const daysDiff = now.diff(momentDate, 'days')

    if (daysDiff > 0) {
      if (FileTransferHelper.isSharedFileExpired(daysDiff)) {
        return { dateText: `Expired`, isExpired: true }
      }

      if (daysDiff === 1) {
        return { dateText: `1 day ago`, isExpired: false }
      }

      return { dateText: `${daysDiff} days ago`, isExpired: false }
    }

    const hoursDiff = now.diff(momentDate, 'hours')

    if (hoursDiff > 0) {
      return { dateText: `${hoursDiff} hours ago`, isExpired: false }
    }

    return { dateText: 'less than hour ago', isExpired: false }
  }, [props.sharedFileInfoRowViewModel.date])

  const handleViewClick = useCallback(
    () =>
      history.push(
        `${props.sharedFileInfoRowViewModel.viewBaseUrl}/${props.sharedFileInfoRowViewModel.blobFileName}`
      ),
    [props.sharedFileInfoRowViewModel.viewBaseUrl, history]
  )

  return (
    <>
      <S.RowBox isEmphasized={props.sharedFileInfoRowViewModel.isEmphasized}>
        <S.EmphasizeIconBox>
          {props.sharedFileInfoRowViewModel.isEmphasized && (
            <Icon color="#337ACE" icon="ReadCircleIcon" size={10} />
          )}
        </S.EmphasizeIconBox>
        <S.TitleBox>{props.sharedFileInfoRowViewModel.title}</S.TitleBox>
        <S.MessageBox>{props.sharedFileInfoRowViewModel.message}</S.MessageBox>
        <S.DateBox isEmphasized={props.sharedFileInfoRowViewModel.isEmphasized}>
          {dateText}
        </S.DateBox>
        {isExpired}
        <S.ViewBox>
          <ActionButton
            isDisabled={isExpired}
            variant={
              props.sharedFileInfoRowViewModel.isEmphasized
                ? 'primary'
                : 'secondaryLight'
            }
            onClick={handleViewClick}
          >
            View
          </ActionButton>
        </S.ViewBox>
      </S.RowBox>
    </>
  )
}

export default SharedFileInfoRowComponent
