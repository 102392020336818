"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCoop = exports.hasCoop = exports.getAllowance = exports.hasAllowance = exports.getMasterDealerCoop = exports.hasMasterDealerCoop = exports.isPrincipal = exports.isCoopAllowanceEqual = exports.deleteCoopAlloance = exports.getCoopAlloance = exports.getCoopAlloanceIndex = exports.getCoopAllowanceText = exports.getCoopAllowancesText = exports.coopAllowanceCapabilityExists = exports.coopRecurringAllowanceExists = exports.coopAllowanceExists = exports.coopAllowancesExists = exports.getCoopAllowanceCapabilityOptions = exports.coopAllowanceCapabilityTypeDescriptions = exports.dealerGroups = exports.getDealerOptions = exports.dealerTypes = void 0;
var enums_1 = require("../enums");
var utils_1 = require("../utils");
var channel_1 = require("./channel");
exports.dealerTypes = [
    // { value: 'authorized_dealer', label: 'Authorized Dealer' },
    { value: 'preferred_dealer', label: 'Preferred' },
    { value: 'top_event_dealer', label: 'Top Event' },
    { value: 'heb_dealer', label: 'HEB' },
];
var getDealerOptions = function () { return [
    // { id: 'authorized_dealer', value: 'Authorized Dealer' },
    { id: 'preferred_dealer', value: 'Preferred' },
    { id: 'top_event_dealer', value: 'Top Event' },
    { id: 'heb_dealer', value: 'HEB' },
]; };
exports.getDealerOptions = getDealerOptions;
exports.dealerGroups = [
    { value: '/dealer', label: 'Member' },
    { value: '/dealerAdmin', label: 'Admin' },
];
exports.coopAllowanceCapabilityTypeDescriptions = [
    {
        id: enums_1.CoopAllowanceCapabilityType.SINGLE_ALLOCATION,
        description: 'Single allocation',
    },
    {
        id: enums_1.CoopAllowanceCapabilityType.MONTHLY_RECURRING,
        description: 'Monthly recurring',
    },
];
var getCoopAllowanceCapabilityOptions = function () {
    return exports.coopAllowanceCapabilityTypeDescriptions.map(function (item) { return ({
        value: item.id.toString(),
        label: item.description,
    }); });
};
exports.getCoopAllowanceCapabilityOptions = getCoopAllowanceCapabilityOptions;
var coopAllowancesExists = function (coopAllowances) {
    return Array.isArray(coopAllowances) && coopAllowances.some(exports.coopAllowanceExists);
};
exports.coopAllowancesExists = coopAllowancesExists;
var coopAllowanceExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.allowance) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.allowance) >= 0);
};
exports.coopAllowanceExists = coopAllowanceExists;
var coopRecurringAllowanceExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.recurringAllowance) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.recurringAllowance) >= 0);
};
exports.coopRecurringAllowanceExists = coopRecurringAllowanceExists;
var coopAllowanceCapabilityExists = function (coopAllowance) {
    return ((0, utils_1.isNumber)(coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.capability) &&
        (coopAllowance === null || coopAllowance === void 0 ? void 0 : coopAllowance.capability) > 0);
};
exports.coopAllowanceCapabilityExists = coopAllowanceCapabilityExists;
var getCoopAllowancesText = function (coopAllowances, outerSeparator, innerSeparator, withBrackets) {
    if (outerSeparator === void 0) { outerSeparator = ' '; }
    if (innerSeparator === void 0) { innerSeparator = ' '; }
    if (withBrackets === void 0) { withBrackets = false; }
    return Array.isArray(coopAllowances)
        ? coopAllowances
            .map(function (item) { return (0, exports.getCoopAllowanceText)(item, innerSeparator, withBrackets); })
            .join(outerSeparator)
        : '';
};
exports.getCoopAllowancesText = getCoopAllowancesText;
var getCoopAllowanceText = function (coopAllowance, separator, withBrackets) {
    var _a;
    if (separator === void 0) { separator = ' '; }
    if (withBrackets === void 0) { withBrackets = false; }
    return "".concat(coopAllowance.masterDealerId).concat((0, exports.coopAllowanceExists)(coopAllowance) ||
        (0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? separator
        : '').concat((0, exports.coopAllowanceExists)(coopAllowance)
        ? utils_1.formatterCurrencyUSD.format(coopAllowance.allowance)
        : '').concat((0, exports.coopAllowanceExists)(coopAllowance) &&
        (0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? separator
        : '').concat((0, exports.coopAllowanceCapabilityExists)(coopAllowance)
        ? "".concat(withBrackets ? '(' : '').concat((_a = exports.coopAllowanceCapabilityTypeDescriptions.find(function (item) { return item.id === coopAllowance.capability; })) === null || _a === void 0 ? void 0 : _a.description).concat((0, exports.coopRecurringAllowanceExists)(coopAllowance)
            ? "".concat(separator).concat(utils_1.formatterCurrencyUSD.format(coopAllowance.recurringAllowance))
            : '').concat(withBrackets ? ')' : '')
        : '');
};
exports.getCoopAllowanceText = getCoopAllowanceText;
var getCoopAlloanceIndex = function (coopAllowances, masterDealerId, channel) {
    if (!Array.isArray(coopAllowances) ||
        !(0, utils_1.isString)(masterDealerId) ||
        !masterDealerId.trim()) {
        return -1;
    }
    if (!!masterDealerId && (0, utils_1.isString)(channel) && !!channel) {
        masterDealerId = (0, channel_1.getMasterDealerId)(masterDealerId.trim().toUpperCase(), channel.trim().toUpperCase());
    }
    return coopAllowances.findIndex(function (item) {
        return item.masterDealerId.trim().toUpperCase() === masterDealerId.toUpperCase();
    });
};
exports.getCoopAlloanceIndex = getCoopAlloanceIndex;
var getCoopAlloance = function (coopAllowances, masterDealerId, channel) {
    var idx = (0, exports.getCoopAlloanceIndex)(coopAllowances, masterDealerId, channel);
    return idx !== -1 ? coopAllowances[idx] : undefined;
};
exports.getCoopAlloance = getCoopAlloance;
var deleteCoopAlloance = function (coopAllowances, coopAllowance, channel) {
    var idx = (0, utils_1.isString)(coopAllowance)
        ? (0, exports.getCoopAlloanceIndex)(coopAllowances, coopAllowance, channel)
        : (0, utils_1.isObject)(coopAllowance)
            ? (0, exports.getCoopAlloanceIndex)(coopAllowances, coopAllowance.masterDealerId)
            : -1;
    if (idx !== -1) {
        coopAllowances.splice(idx, 1);
    }
    return Array.isArray(coopAllowances) && coopAllowances.length
        ? coopAllowances
        : undefined;
};
exports.deleteCoopAlloance = deleteCoopAlloance;
var isCoopAllowanceEqual = function (left, right) {
    return (0, utils_1.isObject)(left) &&
        (0, utils_1.isObject)(right) &&
        (left === right ||
            (left.masterDealerId === right.masterDealerId &&
                left.allowance === right.allowance &&
                left.capability === right.capability &&
                left.recurringAllowance === right.recurringAllowance));
};
exports.isCoopAllowanceEqual = isCoopAllowanceEqual;
var isPrincipal = function (principalOf, masterDealerIds) {
    return Array.isArray(principalOf) &&
        principalOf.length &&
        (Array.isArray(masterDealerIds)
            ? (0, utils_1.intersect)(principalOf, masterDealerIds, utils_1.isStringEqualCI).length > 0
            : (0, utils_1.isString)(masterDealerIds) && masterDealerIds.trim().length
                ? principalOf.findIndex(function (item) {
                    return (0, utils_1.isStringEqualCI)(item, masterDealerIds);
                }) !== -1
                : masterDealerIds == null || masterDealerIds === '');
};
exports.isPrincipal = isPrincipal;
var hasMasterDealerCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance) {
    return (0, utils_1.isObject)(coop)
        ? (coop === null || coop === void 0 ? void 0 : coop.hasCoop) &&
            (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.availableBalance) &&
            (coop === null || coop === void 0 ? void 0 : coop.availableBalance) > 0
        : coop && (0, utils_1.isNumber)(availableBalance) && availableBalance > 0;
};
exports.hasMasterDealerCoop = hasMasterDealerCoop;
var getMasterDealerCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance) {
    return (0, utils_1.isObject)(coop) && (coop === null || coop === void 0 ? void 0 : coop.hasCoop) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.availableBalance)
        ? coop === null || coop === void 0 ? void 0 : coop.availableBalance
        : coop && (0, utils_1.isNumber)(availableBalance)
            ? availableBalance
            : 0.0;
};
exports.getMasterDealerCoop = getMasterDealerCoop;
var hasAllowance = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (coop === null || coop === void 0 ? void 0 : coop.allowanceUsed) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.allowance) && (coop === null || coop === void 0 ? void 0 : coop.allowance) > 0
        : coop && (0, utils_1.isNumber)(allowance) && allowance > 0;
};
exports.hasAllowance = hasAllowance;
var getAllowance = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, allowance) {
    return (0, utils_1.isObject)(coop) && (coop === null || coop === void 0 ? void 0 : coop.allowanceUsed) && (0, utils_1.isNumber)(coop === null || coop === void 0 ? void 0 : coop.allowance)
        ? coop === null || coop === void 0 ? void 0 : coop.allowance
        : coop && (0, utils_1.isNumber)(allowance)
            ? allowance
            : 0.0;
};
exports.getAllowance = getAllowance;
var hasCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance, allowanceUsed, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (0, exports.hasAllowance)(coop) || (0, exports.hasMasterDealerCoop)(coop)
        : (0, exports.hasAllowance)(allowanceUsed, allowance) ||
            (0, exports.hasMasterDealerCoop)(coop, availableBalance);
};
exports.hasCoop = hasCoop;
var getCoop = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
coop, availableBalance, allowanceUsed, allowance) {
    return (0, utils_1.isObject)(coop)
        ? (0, exports.hasAllowance)(coop)
            ? (0, exports.getAllowance)(coop)
            : (0, exports.getMasterDealerCoop)(coop)
        : (0, exports.hasAllowance)(allowanceUsed, allowance)
            ? (0, exports.getAllowance)(allowanceUsed, allowance)
            : (0, exports.getMasterDealerCoop)(coop, availableBalance);
};
exports.getCoop = getCoop;
