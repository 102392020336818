import { OperationResult } from '@monorepo/components/src/models/operation-result'
import { OperationState } from '../view_models/operation-state'

export type ExecuteAsyncOperation<T, E> = () => Promise<OperationResult<T, E>>

export const handleAsyncOperationAsync = async <T, E>(
  executeAsyncOperation: ExecuteAsyncOperation<T, E>,
  callback: (requestState: OperationState<T, E>) => void,
  defaultError: E,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getExceptionError: (error: any) => E
) => {
  callback({ kind: 'loading' })

  try {
    const response = await executeAsyncOperation()

    if (response.kind === 'success') {
      callback({
        kind: 'success',
        data: response.data,
      })
    } else {
      callback({
        kind: 'error',
        error: !response.errorData ? defaultError : response.errorData,
      })
    }
  } catch (error) {
    const errorData = getExceptionError(error)
    callback({
      kind: 'error',
      error: errorData,
    })
  }
}

export const getPercentCompleted = (progressEvent: ProgressEvent) => {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  )

  return percentCompleted
}

const OperationHelper = {
  handleRequestAsync: handleAsyncOperationAsync,
  getPercentCompleted,
}

export default OperationHelper
