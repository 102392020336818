import * as S from './styles'
import ManageTemplate from '../../Manage'
import { ActionButton } from '../../../atoms'
import { useHistory } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import { History } from 'history'
import moment from 'moment'
import { SharedFileInfoViewModel } from '../../../../view_models/file-transfer/shared-file-info-view-model'
import { FileTransferHelper } from '../../../../helpers/file-transfer-helper'
import { SimpleLinearProgress } from '../../../molecules'
import OperationHelper from '../../../../helpers/operation-result'
import { OperationResult } from '@monorepo/components/src/models/operation-result'
import { OperationState } from '../../../../view_models/operation-state'
import throttle from 'lodash/throttle'

const goBackToFileTransfer = (history: History, fileKind?: string) => {
  const url = fileKind
    ? `/admin/file-transfer?tab=${fileKind.toLowerCase()}`
    : '/admin/file-transfer'
  history.push(url)
}

const downloadFileAsync = async (
  blobFileName: string,
  fileName: string,
  setDownloadProgress: (downloadProgress: number) => void
): Promise<OperationResult<boolean, string>> => {
  try {
    setDownloadProgress(0)

    const handleProgress = (progressEvent: ProgressEvent) => {
      const percentCompleted =
        OperationHelper.getPercentCompleted(progressEvent)
      setDownloadProgress(percentCompleted)
    }

    const throttledHandleProgress = throttle(handleProgress, 1000)

    const blob = await FileTransferHelper.tryDownloadSharedFileAsync(
      blobFileName,
      throttledHandleProgress
    )

    setDownloadProgress(100)

    if (blob === null) {
      return {
        kind: 'error',
        errorData: 'Error',
      }
    }

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)

    return {
      kind: 'success',
      data: true,
    }
  } catch (error) {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }
}

const executeDownloadFileRequestAsync = async (
  blobFileName: string,
  fileName: string,
  setDownloadFileRequestState: (
    downloadFileRequestState: OperationState<boolean, string>
  ) => void,
  setDownloadProgress: (downloadProgress: number) => void
) => {
  OperationHelper.handleRequestAsync(
    () => downloadFileAsync(blobFileName, fileName, setDownloadProgress),
    setDownloadFileRequestState,
    'Error',
    (_) => 'Error'
  )
}

interface ViewShareFileInfoComponentProps {
  sharedFileInfoViewModel: SharedFileInfoViewModel
  fileKind?: string
}

const ViewSharedFileInfoComponent: React.FC<ViewShareFileInfoComponentProps> = (
  props
) => {
  const history = useHistory()
  const [downloadFileRequestState, setDownloadFileRequestState] = useState<
    OperationState<boolean, string>
  >({ kind: 'success', data: false })
  const [downloadProgress, setDownloadProgress] = useState(0)

  const fileSize = useMemo(() => {
    return FileTransferHelper.formatBytes(
      props.sharedFileInfoViewModel.fileSize
    )
  }, [props.sharedFileInfoViewModel.fileSize])

  const { sentOn, expiresOn } = useMemo(() => {
    const uploadDateMoment = moment(props.sharedFileInfoViewModel.uploadDate)

    return {
      sentOn: uploadDateMoment.format('MMMM DD, yyyy'),
      expiresOn: uploadDateMoment.add(60, 'days').format('MMMM DD, yyyy'),
    }
  }, [props.sharedFileInfoViewModel.uploadDate])

  const fileKindStr = useMemo(() => {
    return props.fileKind || 'SHARED'
  }, [props.fileKind])

  const handleGoBackClick = useCallback(
    () => goBackToFileTransfer(history, props.fileKind),
    [history, props.fileKind]
  )

  const handleDownloadClickAsync = useCallback(async () => {
    await executeDownloadFileRequestAsync(
      props.sharedFileInfoViewModel.blobFileName,
      props.sharedFileInfoViewModel.fileName,
      setDownloadFileRequestState,
      setDownloadProgress
    )
  }, [
    props.sharedFileInfoViewModel.blobFileName,
    props.sharedFileInfoViewModel.fileName,
  ])

  return (
    <S.MainContainerBox>
      <ManageTemplate
        backText="Back to File Transfer"
        titleText={`VIEW ${fileKindStr} FILE`}
        onGoBack={handleGoBackClick}
      >
        <S.MainBox>
          <S.ContentBox>
            <S.HeaderBox>
              <S.HeaderTextBox>
                <div>{props.sharedFileInfoViewModel.title}</div>
                <div>{`Subject: ${props.sharedFileInfoViewModel.subject}`}</div>
              </S.HeaderTextBox>
              <S.HeaderButtonBox>
                <ActionButton
                  isLoading={downloadFileRequestState.kind === 'loading'}
                  onClick={handleDownloadClickAsync}
                >
                  {downloadFileRequestState.kind === 'loading'
                    ? 'DOWNLOADING...'
                    : 'DOWNLOAD FILE'}
                </ActionButton>
                {downloadFileRequestState.kind === 'loading' && (
                  <S.DownloadProgressBox>
                    <SimpleLinearProgress progress={downloadProgress} />
                  </S.DownloadProgressBox>
                )}
              </S.HeaderButtonBox>
            </S.HeaderBox>
            <S.DescriptionBox>
              <S.DescriptionItemBox>
                <div>{props.sharedFileInfoViewModel.transferInfoTitle}</div>
                <div>{props.sharedFileInfoViewModel.transferInfoContent}</div>
              </S.DescriptionItemBox>
              <S.DescriptionItemBox>
                <div>Subject</div>
                <div>{props.sharedFileInfoViewModel.subject}</div>
              </S.DescriptionItemBox>
              <S.DescriptionItemBox>
                <div>Filename</div>
                <div>{props.sharedFileInfoViewModel.fileName}</div>
              </S.DescriptionItemBox>
              <S.DescriptionItemBox>
                <div>Sent on</div>
                <div>{sentOn}</div>
              </S.DescriptionItemBox>
              <S.DescriptionItemBox>
                <div>Expires on</div>
                <div>{expiresOn}</div>
              </S.DescriptionItemBox>
            </S.DescriptionBox>
            <S.BottomBox>
              <div>1 item, {fileSize} in total</div>
            </S.BottomBox>
          </S.ContentBox>
        </S.MainBox>
      </ManageTemplate>
    </S.MainContainerBox>
  )
}

export default ViewSharedFileInfoComponent
