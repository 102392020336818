"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Input = (_a) => {
    var { id = '', label = '', invalid = false, invalidMessage = '', onChange, type = 'text', accept = 'image/*', onBlur, textarea, disabled } = _a, props = (0, tslib_1.__rest)(_a, ["id", "label", "invalid", "invalidMessage", "onChange", "type", "accept", "onBlur", "textarea", "disabled"]);
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsxs)(S.Wrapper, { children: [textarea ? ((0, jsx_runtime_1.jsx)(S.StyledTextArea, Object.assign({}, props, { label: label, placeholder: " ", onBlur: onBlur, invalid: invalid, id: `input-${id}`, onChange: onChange, invalidMessage: invalidMessage, disabled: disabled }), void 0)) : ((0, jsx_runtime_1.jsx)(S.StyledInput, Object.assign({}, props, { accept: accept, label: label, type: type, placeholder: " ", onBlur: onBlur, invalid: invalid, id: `input-${id}`, onChange: onChange, invalidMessage: invalidMessage, disabled: disabled }), void 0)), (0, jsx_runtime_1.jsx)(S.Label, Object.assign({ htmlFor: `input-${id}` }, { children: label }), void 0), invalid && ((0, jsx_runtime_1.jsx)(S.StyledIcon, { size: 18, icon: "ExclamationMark", hasFill: false, hasStroke: false }, void 0))] }, void 0), invalid && invalidMessage && (0, jsx_runtime_1.jsx)(S.Span, { children: invalidMessage }, void 0)] }, void 0));
};
exports.default = Input;
