import React, { useEffect, useState } from 'react'
import { ExecutiveSummaryReport } from '@monorepo/interfaces'
import { ChannelHelper, http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import { ResponsiveBar } from '@nivo/bar'

interface ExecutiveSummaryProps {
  startDate: Date
  endDate: Date
}

const ExecutiveSummary: React.FC<ExecutiveSummaryProps> = ({
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ExecutiveSummaryReport | null>(null)
  useEffect(() => {
    http
      .get<ExecutiveSummaryReport>({
        url: `/reports/executiveSummary?dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}`,
      })
      .then(({ data }) => {
        setReport(data)
        console.log(JSON.stringify(data))
      })
  }, [endDate, startDate])
  if (!report || report === null) {
    return <PageSpinner />
  }

  const colors = [
    '#7a267b',
    '#337ace',
    '#21528C',
    '#1ebcf8',
    '#e88534',
    '#fdd746',
  ]
  const totalSalesData = report.totalSales.map((total, index) => {
    return { month: report.months[index], total }
  })
  const totalOrdersData = report.totalOrders.map((total, index) => {
    return { month: report.months[index], total }
  })
  const totalDownloadsData = report.totalDownloads.map((total, index) => {
    return { month: report.months[index], total }
  })
  const salesByCategoryData = report.salesByCategory.map((total, index) => {
    return { category: report.categories[index], total }
  })
  const salesByChannelData = report.salesByChannel.map((total, index) => {
    return {
      channel: ChannelHelper.getChannelNameFromLetter(report.channels[index]),
      total,
    }
  })
  const ordersByChannelData = report.ordersByChannel.map((total, index) => {
    return {
      channel: ChannelHelper.getChannelNameFromLetter(report.channels[index]),
      total,
    }
  })
  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  console.log(salesByCategoryData)
  return (
    <S.Container>
      <S.ReportTitle>Executive Summary</S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Date</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(new Date(), 'MM/dd/yyyy')}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.BarChartList>
        {report.totalSales.length > 0 && (
          <S.BarChartListItem>
            <S.BarChartTitle>Total Sales</S.BarChartTitle>
            <S.BarChartSubtitle>
              {moneyFormatter.format(
                Math.round(report.totalSales.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={totalSalesData}
              indexBy="month"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItem>
        )}
        {report.totalOrders.length > 0 && (
          <S.BarChartListItem>
            <S.BarChartTitle>Total Orders</S.BarChartTitle>
            <S.BarChartSubtitle>
              {numberFormatter.format(
                Math.round(report.totalOrders.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={totalOrdersData}
              indexBy="month"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItem>
        )}
        {report.totalDownloads.length === 0 && (
          <S.BarChartListItem>
            <S.BarChartTitle>Total Digital Downloads</S.BarChartTitle>
            <S.BarChartSubtitle>
              {numberFormatter.format(
                Math.round(report.totalDownloads.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={totalDownloadsData}
              indexBy="month"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItem>
        )}
        {report.salesByCategory.length > 0 && (
          <S.BarChartListItemBig>
            <S.BarChartTitle>Total Sales by Category</S.BarChartTitle>
            <S.BarChartSubtitle>
              {moneyFormatter.format(
                Math.round(report.salesByCategory.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={salesByCategoryData}
              indexBy="category"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItemBig>
        )}
        {report.salesByChannel.length > 0 && (
          <S.BarChartListItemBig>
            <S.BarChartTitle>Total Sales by Channel</S.BarChartTitle>
            <S.BarChartSubtitle>
              {moneyFormatter.format(
                Math.round(report.salesByChannel.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={salesByChannelData}
              indexBy="channel"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItemBig>
        )}
        {report.ordersByChannel.length > 0 && (
          <S.BarChartListItemBig>
            <S.BarChartTitle>Total Orders by Channel</S.BarChartTitle>
            <S.BarChartSubtitle>
              {numberFormatter.format(
                Math.round(report.ordersByChannel.reduce((a, b) => a + b))
              )}
            </S.BarChartSubtitle>
            <ResponsiveBar
              // width={400}
              // height={300}
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              data={ordersByChannelData}
              indexBy="channel"
              colors={colors}
              colorBy="indexValue"
              keys={['total']}
              axisBottom={{
                tickSize: 0,
              }}
              enableGridY={false}
              enableLabel={false}
            />
          </S.BarChartListItemBig>
        )}
      </S.BarChartList>
    </S.Container>
  )
}

export default ExecutiveSummary
