import styled from 'styled-components'
import { Box as MuiBox, Tab as MuiTab, makeStyles } from '@material-ui/core'
import {
  TabContext as MuiTabContext,
  TabList as MuiTabList,
  TabPanel as MuiTabPanel,
} from '@material-ui/lab'

export const Box = MuiBox

export const TabsWithRightContentBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RightContentBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const useTabStyles = makeStyles({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'PFDINText',
    fontSize: '1rem',
    fontWeight: 400,
    marginRight: 8,
    minWidth: 'auto',
    color: 'black',
    '&.Mui-selected': {
      color: 'black',
    },
  },
})

export const useTabListStyles = makeStyles({
  indicator: {
    backgroundColor: '#337ace',
    height: 3,
  },
})

export const useTabPanelStyles = makeStyles({
  root: {
    padding: 0,
  },
})

export const Tab = MuiTab
export const TabContext = MuiTabContext
export const TabList = MuiTabList
export const TabPanel = MuiTabPanel
