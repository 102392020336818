import { OrderItem, Product, Event } from '@monorepo/interfaces'
import { EventHelper } from '@monorepo/infra'

class MapToOrderItem {
  private itemType: string

  constructor(itemType: string) {
    this.itemType = itemType
  }

  productItem(product: Product): OrderItem {
    return {
      messages: [],
      price: '',
      product: {
        description: product.description,
        mediaIdACB: product.mediaIdACB,
        sku: product.sku,
        thumbnailUrl: product.variants[0].media[0],
        title: product.title,
        type: product.type,
        vendor: product.vendor,
      },
      quantity: 1,
      status: 0,
      trackingInformation: [],
      type: this.itemType,
    }
  }

  eventItem(event: Event): OrderItem {
    return {
      messages: [],
      price: '',
      product: {
        description: event.description,
        mediaIdACB: event.mediaIdACB,
        sku: event.sku,
        thumbnailUrl: EventHelper.getImageFile(EventHelper.getEventTypeDescription(event.type)),
        title: event.title,
        type: event.type,
        vendor: event.vendor,
      },
      quantity: 1,
      status: 0,
      trackingInformation: [],
      type: this.itemType,
    }
  }
}

export default MapToOrderItem
