import styled from 'styled-components'
import { colors } from '@monorepo/theme'

import {
  Box as MuiBox
} from '@material-ui/core'

export const Box = MuiBox

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TabContentBox = styled(Box)`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`
