import React, { useEffect, useState, useCallback } from 'react'
import {
  ProductDownloadBreakdownRow,
  ProductDownloadBreakdownDto,
} from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import moment from 'moment'

interface ProductDownloadBreakdownProps {
  channel: string
  startDate: Date
  endDate: Date
}

const ProductDownloadBreakdown: React.FC<ProductDownloadBreakdownProps> = ({
  channel,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ProductDownloadBreakdownDto | null>(null)
  useEffect(() => {
    http
      .get<ProductDownloadBreakdownDto>({
        url: `/reports/productDownloadBreakdown?channel=${channel}&dateFrom=${encodeURIComponent(
          moment(startDate).startOf('day').toISOString(true)
        )}&dateTo=${encodeURIComponent(
          moment(endDate).endOf('day').toISOString(true)
        )}`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, startDate, endDate])
  const exportExcel = useCallback(async () => {
    if (Array.isArray(report?.products)) {
      const reportData = report?.products.map((reportRow) => {
        return {
          SKU: reportRow.sku,
          Name: reportRow.name,
          Total: reportRow.total,
        }
      })
      const reportDataActivityByDealer = Array.isArray(report?.activityByDealer)
        ? report?.activityByDealer.map((reportRow) => {
            return {
              SKU: reportRow.sku,
              Name: reportRow.name,
              'Media Id': reportRow.mediaId,
              Channel: reportRow.channel,
              'Order Number': reportRow.orderNumber,
              Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
              'Dealer Id': reportRow.dealerId,
              'Team Member': reportRow.teamMember,
              'Team Member Email': reportRow.teamMemberEmail,
            }
          })
        : undefined
      const reportDataInactiveProducts = Array.isArray(report?.inactiveProducts)
        ? report?.inactiveProducts.map((reportRow) => {
            return {
              SKU: reportRow.sku,
              Name: reportRow.name,
            }
          })
        : undefined
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData as unknown[])
      let wsActivityByDealer
      if (reportDataActivityByDealer) {
        wsActivityByDealer = XLSX.utils.json_to_sheet(
          reportDataActivityByDealer as unknown[]
        )
      }
      let wsInactiveProducts
      if (reportDataInactiveProducts) {
        wsInactiveProducts = XLSX.utils.json_to_sheet(
          reportDataInactiveProducts as unknown[]
        )
      }
      const wb = {
        Sheets: {
          Products: ws,
          ...(wsActivityByDealer && { Activity: wsActivityByDealer }),
          ...(wsInactiveProducts && {
            'Inactive products': wsInactiveProducts,
          }),
        },
        SheetNames: [
          'Products',
          ...(wsActivityByDealer ? ['Activity'] : []),
          ...(wsInactiveProducts ? ['Inactive products'] : []),
        ],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `DigitalDownloads${fileExtension}`)
    }
  }, [report])
  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Digital Downloads
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      {report?.products?.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Image</S.TableHeadCell>
                  <S.TableHeadCell>Product SKU</S.TableHeadCell>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Total Downloads</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report?.products?.map(
                  (item: ProductDownloadBreakdownRow, index: number) => (
                    <S.TableRow key={`products${index}`}>
                      <S.TableCell>
                        <S.TableImageContainer>
                          <S.TableImage src={item.image} />
                        </S.TableImageContainer>
                      </S.TableCell>
                      <S.TableCell>{item.sku}</S.TableCell>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{`${item.total}`}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ProductDownloadBreakdown
