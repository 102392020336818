import * as S from './styles'

interface SimpleLinearProgressProps {
  progress: number
  outerColor?: string
  innerColor?: string
}

const SimpleLinearProgress: React.FC<SimpleLinearProgressProps> = (props) => {
  const progress =
    props.progress < 0 ? 0 : props.progress > 100 ? 100 : props.progress

  return (
    <S.OuterBox outerColor={props.outerColor}>
      <S.InnerBox
        innerColor={props.innerColor}
        widthPercent={progress}
      ></S.InnerBox>
    </S.OuterBox>
  )
}

export default SimpleLinearProgress
