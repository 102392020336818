"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoopAllowanceCapabilityType = exports.DealerType = void 0;
var DealerType;
(function (DealerType) {
    DealerType["AUTHORIZED_DEALER"] = "authorized_dealer";
    DealerType["PREFERRED_DEALER"] = "preferred_dealer";
    DealerType["TOP_EVENT_DEALER"] = "top_event_dealer";
    DealerType["HEB_DEALER"] = "heb_dealer";
})(DealerType = exports.DealerType || (exports.DealerType = {}));
var CoopAllowanceCapabilityType;
(function (CoopAllowanceCapabilityType) {
    CoopAllowanceCapabilityType[CoopAllowanceCapabilityType["NONE"] = 0] = "NONE";
    CoopAllowanceCapabilityType[CoopAllowanceCapabilityType["SINGLE_ALLOCATION"] = 1] = "SINGLE_ALLOCATION";
    CoopAllowanceCapabilityType[CoopAllowanceCapabilityType["MONTHLY_RECURRING"] = 2] = "MONTHLY_RECURRING";
})(CoopAllowanceCapabilityType = exports.CoopAllowanceCapabilityType || (exports.CoopAllowanceCapabilityType = {}));
