import { useEffect, useState } from 'react'
import * as S from './styles'
import { FileTransferHelper } from '../../../../helpers/file-transfer-helper'
import OperationHelper from '../../../../helpers/operation-result'
import { OperationState } from '../../../../view_models/operation-state'
import SharedFileInfoRowsComponent from '../../../organisms/FileTransfer/SharedFileInfoRowsComponent'
import { SharedFileInfoRowViewModel } from '../../../../view_models/file-transfer/shared-file-info-row-view-model'
import { OperationResult } from '@monorepo/components/src/models/operation-result'
import { Spinner } from '@monorepo/components'
import moment from 'moment'

const getSharedFileInfoRowViewModelsAsync = async (): Promise<
  OperationResult<SharedFileInfoRowViewModel[], string>
> => {
  const receivedSharedFilesPageResult =
    await FileTransferHelper.getReceivedSharedFilesPageAsync(1, 50)

  if (receivedSharedFilesPageResult.kind === 'error') {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }

  const sharedFileInfoRowViewModels: SharedFileInfoRowViewModel[] =
    receivedSharedFilesPageResult.data.data
      .map((x) => ({
        title: `${x.author.name} sent you a file`,
        message: `Message: ${x.subject}`,
        date: moment(x.uploadDate).toDate(),
        isEmphasized: !x.isRead,
        viewBaseUrl: '/admin/file-transfer/received',
        blobFileName: x.blobFileName,
      }))
      .sort((a, b) => b.date.getTime() - a.date.getTime())

  return {
    kind: 'success',
    data: sharedFileInfoRowViewModels,
  }
}

const loadSharedFileInfoRowViewModelsAsync = async (
  setSharedFileInfoRowViewModelsRequestState: (
    sharedFileInfoRowViewModelsRequestState: OperationState<
      SharedFileInfoRowViewModel[],
      string
    >
  ) => void
) => {
  await OperationHelper.handleRequestAsync(
    getSharedFileInfoRowViewModelsAsync,
    setSharedFileInfoRowViewModelsRequestState,
    'Error',
    (_) => 'Error'
  )
}

const ReceivedSharedFilesComponent: React.FC = () => {
  const [
    sharedFileInfoRowViewModelsRequestState,
    setSharedFileInfoRowViewModelsRequestState,
  ] = useState<OperationState<SharedFileInfoRowViewModel[], string>>({
    kind: 'loading',
  })

  useEffect(() => {
    loadSharedFileInfoRowViewModelsAsync(
      setSharedFileInfoRowViewModelsRequestState
    )
  }, [])

  return (
    <>
      <S.MainBox>
        {sharedFileInfoRowViewModelsRequestState.kind === 'loading' && (
          <S.SpinnerBox>
            <Spinner />
          </S.SpinnerBox>
        )}
        {sharedFileInfoRowViewModelsRequestState.kind === 'error' && (
          <S.ErrorBox>
            {sharedFileInfoRowViewModelsRequestState.error}
          </S.ErrorBox>
        )}
        {sharedFileInfoRowViewModelsRequestState.kind === 'success' && (
          <SharedFileInfoRowsComponent
            fileKind='RECEIVED'
            sharedFileInfoRowViewModels={
              sharedFileInfoRowViewModelsRequestState.data
            }
          />
        )}
      </S.MainBox>
    </>
  )
}

export default ReceivedSharedFilesComponent
