/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Event,
  Order,
  OrderItem,
  OrderProps,
  Product,
  TrackingInformation,
  Dealer,
} from '@monorepo/interfaces'
import * as Yup from 'yup'
import { ProductSearch, DealerSearch } from '../../../../components'
import React, { useCallback, useState, useEffect } from 'react'
import {
  ChannelHelper,
  http,
  httpUserInfo,
  OrderHelper,
  OrderItemStatus,
  OrderPaymentStatus,
  useCrud,
} from '@monorepo/infra'
import ManageTemplate from '../../Manage'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import MapToOrderItem from '../mapping/toOrderItem'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { colors } from '@monorepo/theme'
import { useFormik } from 'formik'
import Select from 'react-select'

type OrderCreateProps = OrderProps
export type ActiveItem = {
  item: Event | Product
  type?: string
}

const mask = createNumberMask({
  prefix: '$',
  suffix: '',
  allowDecimal: true,
})

const addressBase = {
  address1: '',
  city: '',
  state: '',
  zipCode: '',
}

const OrderSchema = Yup.object().shape({
  shippingAddress: Yup.object().shape({
    address1: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
  billingAddress: Yup.object().shape({
    address1: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string(),
  }),
})

const MasterDealerIdChannelSchema = Yup.object().shape({
  masterDealerId: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  masterDealerChannel: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
})

const setPaymentStatus = (total: number) => {
  if (total === 0) {
    return OrderPaymentStatus.PAID
  }

  return OrderPaymentStatus.UNPAID
}

const CreateOrders: React.FC<OrderCreateProps> = () => {
  const [selectedDealer, setSelectedDealer] = useState<Dealer | null>()
  const [dealers, setDealers] = useState<Dealer>()
  const [order, setOrder] = useState<Order>({
    createdBy: '',
    createdOn: '',
    customer: {
      billingAddress: addressBase,
      masterDealerId: '',
      shippingAddress: addressBase,
      user: '',
    },
    history: [],
    items: [],
    number: 0,
    paymentStatus: 1,
    payments: [],
    status: 1,
  })
  const [total, setTotal] = useState<number>(0)
  const [selectedProducs, setSelectedProducts] = useState<OrderItem[]>([])
  const [masterDealerIds, setMasterDealerIds] = useState<
    { value: string; label: string }[] | undefined
  >(undefined)
  const [channels, setChannels] = useState<
    { value: string; label: string }[] | undefined
  >(undefined)
  const [validationSchema, setValidationSchema] = useState(OrderSchema)
  const history = useHistory()
  const formik = useFormik({
    onSubmit: () => {},
    validationSchema: () => validationSchema,
    initialValues: {
      billingAddress: {
        address1: '',
        state: '',
        city: '',
        zipCode: '',
      },
      shippingAddress: {
        address1: '',
        state: '',
        city: '',
        zipCode: '',
      },
      masterDealerId: null,
      masterDealerChannel: null,
    },
  })

  const { fetch: productFetch, search: productSearch } = useCrud(
    '/product',
    '_id',
    { revalidateOnFocus: false }
  )
  const { data: products } = productFetch

  const { fetch: eventFetch, search: eventSearch } = useCrud(`/event`, '_id', {
    revalidateOnFocus: false,
  })
  const { data: events } = eventFetch

  const onProductSearchClick = (item: Product | Event, type?: string) => {
    const mapToOrderItem = new MapToOrderItem(type as string)

    if (type === 'event') {
      const data = mapToOrderItem.eventItem(item as Event)
      setSelectedProducts([...order.items, data])
    }

    if (type === 'product') {
      const data = mapToOrderItem.productItem(item as Product)
      setSelectedProducts([...order.items, data])
    }
  }

  const handleGoBack = useCallback(() => {
    history.push('/orders')
  }, [history])

  const onProductSearch = (search: Record<string, unknown>) => {
    search['status'] = 'active'
    search['withHidden'] = true
    productSearch(search)
    eventSearch(search)
  }

  const onDealerSearchClick = (item: Dealer) => {
    setSelectedDealer(item)
    setMasterDealerCard(item)
    setValidationSchema(OrderSchema.concat(MasterDealerIdChannelSchema))
  }

  const setMasterDealerCard = (dealer: Dealer) => {
    if (dealer && dealer.dealer) {
      const masterDealerIds = ChannelHelper.getMasterDealerIds(
        dealer.dealer.associatedMasterDealers
      ).map((id) => ({ value: id, label: id }))
      if (masterDealerIds.length) {
        setMasterDealerIds(masterDealerIds)
      } else {
        setMasterDealerIds(undefined)
        formik.setValues({ ...formik.values, masterDealerId: null })
      }

      const channels = ChannelHelper.getChannels(
        dealer.dealer.associatedMasterDealers
      ).map((channel) => ({
        value: channel.toUpperCase(),
        label: channel.toUpperCase(),
      }))
      if (channels.length) {
        setChannels(channels)
      } else {
        setChannels(undefined)
        formik.setValues({ ...formik.values, masterDealerChannel: null })
      }
    } else {
      resetMasterDealerCard()
    }
  }

  const resetMasterDealerCard = () => {
    setMasterDealerIds(undefined)
    setChannels(undefined)
    formik.setValues({
      ...formik.values,
      masterDealerId: null,
      masterDealerChannel: null,
    })
    setValidationSchema(OrderSchema)
  }

  const onDealerSearch = (item: Record<string, unknown>) => {
    httpUserInfo
      .get<Dealer>({ url: `/dealer/${item.search}` })
      .then((res) => res.data)
      .then((res) => setDealers(res))
  }

  const createOrder = () => {
    const customer = {
      user: dealers?.email?.trim()?.toLowerCase(),
      masterDealerId:
        formik.values.masterDealerId && formik.values.masterDealerChannel
          ? ChannelHelper.getMasterDealerId(
              (formik.values.masterDealerId as { label: string; value: string })
                .value,
              (
                formik.values.masterDealerChannel as {
                  label: string
                  value: string
                }
              ).value
            )
          : undefined,
      billingAddress: {
        firstName: dealers?.firstName,
        lastName: dealers?.lastName,
        ...formik.values.billingAddress,
      },
      shippingAddress: {
        firstName: dealers?.firstName,
        lastName: dealers?.lastName,
        ...formik.values.shippingAddress,
      },
    }
    const items = order.items.map((item) => {
      delete item.type
      return {
        ...item,
        price: item.price.replaceAll(/\$|,/g, ''),
        status: OrderItemStatus.PRICE_UPDATED,
      }
    })
    const itemSubtotal = OrderHelper.getItemsSubtotal(items)
    const orderToSave = {
      ...order,
      customer,
      items,
      createdBy: 'system@thedirectvmarketingzone.com',
      createdOn: new Date().toISOString(),
      paymentStatus: setPaymentStatus(itemSubtotal),
    }

    http.post({ url: '/order', data: orderToSave }).then(() => handleGoBack())
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const handleChangePrice = (e: any, changeIndex: number) => {
    const value = e.target.value

    const newItems = order.items.map((item, index) => {
      if (changeIndex === index) {
        return {
          ...item,
          price: value,
          status: OrderItemStatus.PRICE_UPDATED,
          product: {
            ...item.product,
            productUrl: 'https://thedirectvmarketingzone.com',
          },
        }
      }
      return item
    })
    const newOrder = {
      ...order,
      items: [...newItems],
    }

    setOrder(newOrder)
  }

  const handleChangeQuantity = (e: any, changeIndex: number) => {
    const newItems = order.items.map((item, index) => {
      if (changeIndex === index) {
        return {
          ...item,
          quantity: Number(e.target.value),
        }
      }
      return item
    })
    const newOrder = {
      ...order,
      items: [...newItems],
    }

    setOrder(newOrder)
  }

  useEffect(() => {
    if (selectedProducs) {
      setOrder({
        ...order,
        items: selectedProducs,
      })
    }
  }, [selectedProducs])

  useEffect(() => {
    const newTotal = order.items.reduce(
      (acc, item) =>
        acc + Number(item.price.replace(/\$/g, '').replaceAll(',', '')),
      0
    )

    setTotal(newTotal)
  }, [order])

  useEffect(() => {
    formik.validateForm()
  }, [validationSchema])

  return (
    <ManageTemplate
      backText="Back to the orders"
      titleText="Create Order"
      onGoBack={handleGoBack}
      headerRightSideChildren={
        <S.ButtonsContainer>
          <S.FormButton onClick={handleGoBack}>Discard</S.FormButton>
          {formik.isValid && formik.dirty && (
            <S.FormButton backgroundColor={colors.blue} onClick={createOrder}>
              Save
            </S.FormButton>
          )}
        </S.ButtonsContainer>
      }
    >
      <S.Wrapper>
        <S.Principal>
          <S.Card>
            <S.SubTitle>Add Product</S.SubTitle>
            <ProductSearch
              onClick={onProductSearchClick}
              onSearch={onProductSearch}
              products={products}
              events={events}
            />
          </S.Card>

          <S.ItemContainer>
            <S.ItemList>
              {order.items.map((item: OrderItem, index: number) => {
                return (
                  <S.ItemListItem key={`order-item-${index}`}>
                    <S.ItemRow>
                      <S.ItemImage src={item.product.thumbnailUrl} />
                      <S.ItemDetail>
                        <S.ItemTitle>{item.product.title}</S.ItemTitle>
                        <S.ItemText>
                          {OrderHelper.getItemProductTypeDescription(
                            item.product
                          )}
                        </S.ItemText>
                        <S.ItemText>{`SKU: ${item.product.sku}`}</S.ItemText>
                        {item.notes && (
                          <S.ItemText>{`Notes from customer: ${item.notes}`}</S.ItemText>
                        )}
                        {item.trackingInformation?.length
                          ? item.trackingInformation.map(
                              (tracking: TrackingInformation, jdx: number) => {
                                return (
                                  <S.ItemText
                                    key={`order-item-${index}-tracking-${jdx}`}
                                  >{`${tracking.carrier}: ${tracking.tracking}`}</S.ItemText>
                                )
                              }
                            )
                          : undefined}
                      </S.ItemDetail>
                      <S.ItemPrice>
                        <S.ItemPriceField>
                          <label>Quantity</label>
                          <input
                            id="quantity"
                            type="number"
                            value={item.quantity}
                            onChange={(e) => handleChangeQuantity(e, index)}
                            disabled={true}
                          />
                        </S.ItemPriceField>
                        <S.ItemPriceField>
                          <label>Price</label>
                          <MaskedInput
                            mask={mask}
                            id="price"
                            type="text"
                            value={item.price}
                            onChange={(e) => handleChangePrice(e, index)}
                          />
                        </S.ItemPriceField>
                      </S.ItemPrice>
                    </S.ItemRow>
                    <S.Line />
                  </S.ItemListItem>
                )
              })}
            </S.ItemList>
          </S.ItemContainer>

          <S.Card>
            <S.ItemContainer>
              <S.PriceRow>
                <S.PriceDescription>Total</S.PriceDescription>
                <S.PriceAmount fontWeight={'700'}>
                  {formatter.format(total)}
                </S.PriceAmount>
              </S.PriceRow>
            </S.ItemContainer>
          </S.Card>

          <S.Card>
            <S.SubTitle>Shipping Address</S.SubTitle>
            <S.AddressField>
              <label>Address</label>
              <input
                id="shippingAddress.address1"
                name="shippingAddress.address1"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.shippingAddress.address1}
              />
            </S.AddressField>
            <S.AddressField>
              <label>City</label>
              <input
                id="shippingAddress.city"
                name="shippingAddress.city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.shippingAddress.city}
              />
            </S.AddressField>
            <S.AddressField>
              <label>State</label>
              <input
                id="shippingAddress.state"
                name="shippingAddress.state"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.shippingAddress.state}
              />
            </S.AddressField>
            <S.AddressField>
              <label>Zipcode</label>
              <input
                id="shippingAddress.zipCode"
                name="shippingAddress.zipCode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.shippingAddress.zipCode}
              />
            </S.AddressField>
          </S.Card>

          <S.Card>
            <S.SubTitle>Billing Address</S.SubTitle>
            <S.AddressField>
              <label>Address</label>
              <input
                id="billingAddress.address1"
                name="billingAddress.address1"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billingAddress.address1}
              />
            </S.AddressField>
            <S.AddressField>
              <label>City</label>
              <input
                id="billingAddress.city"
                name="billingAddress.city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billingAddress.city}
              />
            </S.AddressField>
            <S.AddressField>
              <label>State</label>
              <input
                id="billingAddress.state"
                name="billingAddress.state"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billingAddress.state}
              />
            </S.AddressField>
            <S.AddressField>
              <label>Zipcode</label>
              <input
                id="billingAddress.zipCode"
                name="billingAddress.zipCode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.billingAddress.zipCode}
              />
            </S.AddressField>
          </S.Card>
        </S.Principal>
        <S.Options>
          <S.Card>
            <S.SubTitle>Customer</S.SubTitle>
            <DealerSearch
              onClick={onDealerSearchClick}
              onSearch={onDealerSearch}
              dealers={dealers}
            />
            {selectedDealer && (
              <>
                <br />
                <p>{`${selectedDealer?.firstName} ${selectedDealer?.lastName}`}</p>
                <p>{selectedDealer?.email}</p>
                <S.RemoveButton
                  onClick={() => {
                    setSelectedDealer(null)
                    resetMasterDealerCard()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  remove
                </S.RemoveButton>
              </>
            )}
          </S.Card>
          <S.Card>
            <S.SubTitle>Master Dealer ID and Channel</S.SubTitle>
            <S.SelectWithErrorContainer>
              <Select
                id="masterDealerId"
                name="masterDealerId"
                placeholder="ID"
                options={masterDealerIds}
                value={formik.values.masterDealerId}
                isSearchable={false}
                onChange={(value) =>
                  formik.setFieldValue('masterDealerId', value)
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              {formik.errors.masterDealerId && (
                <S.SelectError>
                  <S.SelectErrorMessage>* Required</S.SelectErrorMessage>
                </S.SelectError>
              )}
            </S.SelectWithErrorContainer>
            <S.SelectWithErrorContainer>
              <Select
                id="masterDealerChannel"
                name="masterDealerChannel"
                placeholder="Channel"
                options={channels}
                value={formik.values.masterDealerChannel}
                isSearchable={false}
                onChange={(value) =>
                  formik.setFieldValue('masterDealerChannel', value)
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              {formik.errors.masterDealerChannel && (
                <S.SelectError>
                  <S.SelectErrorMessage>* Required</S.SelectErrorMessage>
                </S.SelectError>
              )}
            </S.SelectWithErrorContainer>
          </S.Card>
        </S.Options>
      </S.Wrapper>
    </ManageTemplate>
  )
}

export default CreateOrders
