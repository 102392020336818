"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mediaCampaignButtonTexts = exports.mediaCampaignTitles = exports.outOfHomeCampaignButtonText = exports.threeMonthDigitalDisplayCampaignButtonText = exports.radioCampaignButtonText = exports.printCampaignButtonText = exports.paidSocialMediaCampaignButtonText = exports.promoMediaCampaignButtonText = exports.noneMediaCampaignButtonText = exports.outOfHomeCampaignTitleRegex = exports.threeMonthDigitalDisplayCampaignTitleRegex = exports.radioCampaignTitleRegex = exports.printCampaignTitleRegex = exports.paidSocialMediaCampaignTitleRegex = exports.promoMediaCampaignTitleRegex = exports.outOfHomeCampaignTitle = exports.threeMonthDigitalDisplayCampaignTitle = exports.radioCampaignTitle = exports.printCampaignTitle = exports.paidSocialMediaCampaignTitle = exports.promoMediaCampaignTitle = exports.noneMediaCampaignTitle = void 0;
exports.noneMediaCampaignTitle = 'None';
exports.promoMediaCampaignTitle = 'Promo';
exports.paidSocialMediaCampaignTitle = 'Paid Social Media Campaign';
exports.printCampaignTitle = 'Print Campaign';
exports.radioCampaignTitle = 'Radio Campaign';
exports.threeMonthDigitalDisplayCampaignTitle = '3 Month Digital Display Campaign';
exports.outOfHomeCampaignTitle = 'Out of Home Campaign';
exports.promoMediaCampaignTitleRegex = new RegExp(exports.promoMediaCampaignTitle, 'i');
exports.paidSocialMediaCampaignTitleRegex = new RegExp(exports.paidSocialMediaCampaignTitle, 'i');
exports.printCampaignTitleRegex = new RegExp(exports.printCampaignTitle, 'i');
exports.radioCampaignTitleRegex = new RegExp(exports.radioCampaignTitle, 'i');
exports.threeMonthDigitalDisplayCampaignTitleRegex = new RegExp(exports.threeMonthDigitalDisplayCampaignTitle, 'i');
exports.outOfHomeCampaignTitleRegex = new RegExp(exports.outOfHomeCampaignTitle, 'i');
exports.noneMediaCampaignButtonText = 'None';
exports.promoMediaCampaignButtonText = 'PROMO';
exports.paidSocialMediaCampaignButtonText = 'PAID SOCIAL MEDIA';
exports.printCampaignButtonText = 'PRINT CAMPAIGN';
exports.radioCampaignButtonText = 'RADIO CAMPAIGN';
exports.threeMonthDigitalDisplayCampaignButtonText = 'BANNER ADS';
exports.outOfHomeCampaignButtonText = 'OOH CAMPAIGN';
exports.mediaCampaignTitles = [
    exports.noneMediaCampaignTitle,
    exports.promoMediaCampaignTitle,
    exports.paidSocialMediaCampaignTitle,
    exports.printCampaignTitle,
    exports.radioCampaignTitle,
    exports.threeMonthDigitalDisplayCampaignTitle,
    exports.outOfHomeCampaignTitle,
];
exports.mediaCampaignButtonTexts = [
    exports.noneMediaCampaignTitle,
    exports.promoMediaCampaignTitle,
    exports.paidSocialMediaCampaignTitle,
    exports.printCampaignTitle,
    exports.radioCampaignTitle,
    exports.threeMonthDigitalDisplayCampaignTitle,
    exports.outOfHomeCampaignTitle,
];
