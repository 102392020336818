import * as S from './styles'
import { SharedFileInfoRowViewModel } from '../../../../view_models/file-transfer/shared-file-info-row-view-model'
import SharedFileInfoRowComponent from '../SharedFileInfoRowComponent'
import { useMemo } from 'react'

interface SharedFileInfoRowsComponentProps {
  sharedFileInfoRowViewModels: SharedFileInfoRowViewModel[]
  fileKind?: string
}

const SharedFileInfoRowsComponent: React.FC<SharedFileInfoRowsComponentProps> =
  (props) => {
    const fileKindStr = useMemo(() => {
      return props.fileKind || 'SHARED'
    }, [props.fileKind])

    return (
      <>
        <S.MainBox>
          {props.sharedFileInfoRowViewModels.length < 1 && (
            <S.NoMessagesBox>NO {fileKindStr} FILES YET</S.NoMessagesBox>
          )}
          {props.sharedFileInfoRowViewModels.length > 0 && (
            <S.RowsBox>
              {props.sharedFileInfoRowViewModels.map((x) => (
                <S.RowBox>
                  <SharedFileInfoRowComponent
                    key={x.blobFileName}
                    sharedFileInfoRowViewModel={x}
                  />
                </S.RowBox>
              ))}
            </S.RowsBox>
          )}
        </S.MainBox>
      </>
    )
  }

export default SharedFileInfoRowsComponent
