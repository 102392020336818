import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = MuiBox

export const OuterBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const InnerBox = styled(Box)<{
  modalWidth?: string
  modalHeight?: string
  backgroundColor?: string
}>`
    z-index: 1001;
    width: ${(props) => (props.modalWidth ? props.modalWidth : 'auto')};
    height: ${(props) => (props.modalHeight ? props.modalHeight : 'auto')};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#ffffff')};
`
