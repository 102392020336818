import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = MuiBox

export const MainBox = styled(Box)`
  width: 100%;
`

export const NoMessagesBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RowsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`

export const RowBox = styled(Box)`
  display: flex;
`
