import ReactDOM from 'react-dom'
import * as S from './styles'

export interface SimpleModalProps {
  width?: string
  height?: string
  backgroundColor?: string
  isOpen: boolean
  children: React.ReactNode
}

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  if (!props.isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    <S.OuterBox className="modal-outer">
      <S.InnerBox
        modalWidth={props.width}
        modalHeight={props.height}
        backgroundColor={props.backgroundColor}
        className="modal-inner"
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </S.InnerBox>
    </S.OuterBox>,
    document.getElementById('modal-root') as HTMLElement
  )
}

export default SimpleModal
