import * as S from './styles'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { SharedFileInfoViewModel } from '../../../../view_models/file-transfer/shared-file-info-view-model'
import { FileTransferHelper } from '../../../../helpers/file-transfer-helper'
import ViewSharedFileInfoComponent from '../ViewSharedFileInfoComponent'
import { OperationResult } from '@monorepo/components/src/models/operation-result'
import OperationHelper from '../../../../helpers/operation-result'
import { OperationState } from '../../../../view_models/operation-state'
import { Spinner } from '@monorepo/components'

interface ViewSentSharedFileComponentProps {
  blobFileName: string
}

const getSharedFileInfoViewModelAsync = async (
  blobFileName: string
): Promise<OperationResult<SharedFileInfoViewModel | null, string>> => {
  const sentSharedFile = await FileTransferHelper.tryGetSentSharedFileAsync(
    blobFileName
  )

  if (sentSharedFile.kind === 'error') {
    return {
      kind: 'error',
      errorData: 'Error',
    }
  }

  if (sentSharedFile.data === null) {
    return {
      kind: 'success',
      data: null,
    }
  }

  const sharedFileInfoViewModel: SharedFileInfoViewModel = {
    title: `You sent a file`,
    subject: sentSharedFile.data.subject,
    transferInfoTitle: `Receivers`,
    transferInfoContent: sentSharedFile.data.receivers.join(', '),
    fileName: sentSharedFile.data.fileName,
    fileSize: sentSharedFile.data.fileSize,
    uploadDate: moment(sentSharedFile.data.uploadDate).toDate(),
    blobFileName: sentSharedFile.data.blobFileName,
  }

  return {
    kind: 'success',
    data: sharedFileInfoViewModel,
  }
}

const loadSharedFileInfoViewModelAsync = async (
  blobFileName: string,
  setSharedFileInfoViewModelRequestState: (
    sharedFileInfoViewModelRequestState: OperationState<
      SharedFileInfoViewModel | null,
      string
    >
  ) => void
) => {
  await OperationHelper.handleRequestAsync(
    () => getSharedFileInfoViewModelAsync(blobFileName),
    setSharedFileInfoViewModelRequestState,
    'Error',
    (_) => 'Error'
  )
}

const ViewSentSharedFileComponent: React.FC<ViewSentSharedFileComponentProps> =
  (props) => {
    const [
      sharedFileInfoViewModelRequestState,
      setSharedFileInfoViewModelRequestState,
    ] = useState<OperationState<SharedFileInfoViewModel | null, string>>({
      kind: 'loading',
    })

    useEffect(() => {
      loadSharedFileInfoViewModelAsync(
        props.blobFileName,
        setSharedFileInfoViewModelRequestState
      )
    }, [])

    return (
      <>
        <S.MainBox>
          {sharedFileInfoViewModelRequestState.kind === 'loading' && (
            <Spinner />
          )}
          {sharedFileInfoViewModelRequestState.kind === 'error' && (
            <div>{sharedFileInfoViewModelRequestState.error}</div>
          )}
          {sharedFileInfoViewModelRequestState.kind === 'success' &&
            sharedFileInfoViewModelRequestState.data !== null && (
              <ViewSharedFileInfoComponent
                fileKind="SENT"
                sharedFileInfoViewModel={
                  sharedFileInfoViewModelRequestState.data
                }
              />
            )}
        </S.MainBox>
      </>
    )
  }

export default ViewSentSharedFileComponent
