import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
`

export const FileTransfer = styled.h1`
  font-family: Jubilat;
  font-style: italic;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
`
