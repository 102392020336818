"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaCampaign = void 0;
var MediaCampaign;
(function (MediaCampaign) {
    MediaCampaign[MediaCampaign["None"] = 0] = "None";
    MediaCampaign[MediaCampaign["Promo"] = 1] = "Promo";
    MediaCampaign[MediaCampaign["PaidSocialMedia"] = 2] = "PaidSocialMedia";
    MediaCampaign[MediaCampaign["Print"] = 3] = "Print";
    MediaCampaign[MediaCampaign["Radio"] = 4] = "Radio";
    MediaCampaign[MediaCampaign["ThreeMonthDigitalDisplay"] = 5] = "ThreeMonthDigitalDisplay";
    MediaCampaign[MediaCampaign["OutOfHome"] = 6] = "OutOfHome";
})(MediaCampaign = exports.MediaCampaign || (exports.MediaCampaign = {}));
