"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line import/no-anonymous-default-export
exports.default = {
    black: '#000000',
    white: '#ffffff',
    lightGrey: '#C3CBCD',
    lighterGrey: '#F3F3F3',
    softGrey: '#F4F6F8',
    slateGrey: '#707D8D',
    highlightGrey: '#BABABA',
    delicateGrey: '#F1F2F4',
    mediumGrey: '#9FA8B3',
    grey: '#58677A',
    turquoise: '#1EBCF8',
    lapis: '#21528C',
    blue: '#337ACE',
    dark: '#1D2328',
    darkBlue: '#102641',
    yellow: '#FDD746',
    orange: '#E88534',
    green: '#81c370',
    lightRed: '#ffcccb',
    red: '#F8003B',
    purple: '#7A267B',
};
