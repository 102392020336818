import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Icon as IconComponent } from '@monorepo/components'
import { colors } from '@monorepo/theme'

const CommonFontStyle = css`
  letter-spacing: -0.2px;
  font-family: PFDINText;
  color: ${colors.black};
`

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 75px;
`

export const SectionContainer = styled.div`
  margin-bottom: 105px;
`

export const Subtitle = styled.h2`
  ${CommonFontStyle}
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4.5rem;
`

export const Card = styled(NavLink)`
  transition: background-color 0.25s ease-in-out;
  padding: 6px;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const CardTitle = styled.h3`
  letter-spacing: -0.2px;
  font-size: 25px;
  font-weight: 500;
  font-family: PFDINText;
  color: ${colors.black};
`

export const Icon = styled(IconComponent)`
  margin-right: 7px;
`

export const CardDescription = styled.p`
  ${CommonFontStyle}
  font-size: 14px;
`
