import styled from 'styled-components'

import { Box as MuiBox } from '@material-ui/core'



export const Box = MuiBox

export const MainContainerBox = styled(Box)`
  width: 100%;
  height: 100%;
`

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`

export const ContentBox = styled(Box)`
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #ffffff;
  padding: 2rem;
  margin-bottom: 3rem;
`

export const HeaderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #666666;
`

export const HeaderTextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
`

export const HeaderButtonBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const DownloadProgressBox = styled(Box)`
  width: 100%;
  height: 1rem;
`

export const DescriptionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const DescriptionItemBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.5rem;
  line-height: 1.8rem;
`

export const BottomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 0.9rem;
  color: #9FA8B3;
  font-family: 'PF DIN Text Pro';
`
