import * as S from './styles'
import { useKeycloak } from '@react-keycloak/web'
import { Event, Pagination as PaginationType } from '@monorepo/interfaces'
import { ImportLink, PaginationDetails, ExportXlsLink } from '../../atoms'
import {
  Pagination,
  SectionEvents,
  SectionEventsList,
  TabList,
} from '../../../components'
import React, { useState } from 'react'
import {
  KeycloakHelper,
  useCrud,
  usePaginationSearchParams,
  useQueryParams,
} from '@monorepo/infra'

import { Icon, PageSpinner } from '@monorepo/components'
import { SearchTerm } from '../../molecules'
import { colors } from '@monorepo/theme'
import { useHistory } from 'react-router-dom'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'
import BulkDeleteLink from '../../atoms/BulkDeleteLink'
import { UploadError } from '../Products'
import { exportXls } from '../Utils'

type EventsFetch = PaginationType & {
  data: Event[]
}

const Events = (): JSX.Element => {
  const { keycloak } = useKeycloak()
  const eventBaseURL = '/event'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const [uploadErrors, setUploadErrors] = useState<UploadError[]>([])
  const [showDialog, setShowDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const { t } = useTextTranslation('admin')
  const history = useHistory()

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const apiURL = `${eventBaseURL}?${apiSearchParams}`

  const { fetch } = useCrud<Event>(apiURL, '_id', {
    revalidateOnFocus: false,
  })

  const { data, loading } = fetch
  const { data: events, ...pagination } = data as EventsFetch
  pagination.page = Number(pagination.page)

  const handleAddEvent = () => history.push('events/create')

  const handleClearSearchTerm = () => setSearchTerm('')

  const handleUploadFile = (file: File | null) => {
    if (file) {
      setIsUploading(true)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_REST_BASE_URL}/import-event`
      const formData = new FormData()
      formData.append('file', file)
      const config = {
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          setIsUploading(false)
          if (success.data && success.data.hasError) {
            setUploadErrors(success.data.errors)
            setFile(null)
          } else {
            setShowDialog(false)
          }
        })
        .catch((error) => {
          setIsUploading(false)
          setShowDialog(false)
        })
    }
  }

  const handleUploadDeleteFile = (file: File | null) => {
    if (file) {
      setIsUploading(true)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_REST_BASE_URL}/event/bulk-delete`
      const formData = new FormData()
      formData.append('file', file)
      const config = {
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          setIsUploading(false)
          setShowDeleteDialog(false)
          history.go(0)
        })
        .catch((error) => {
          setIsUploading(false)
          setShowDeleteDialog(false)
        })
    }
  }

  const open = () => setShowDialog(true)
  const close = () => {
    setShowDialog(false)
    setUploadErrors([])
  }
  const openDeleteDialog = () => setShowDeleteDialog(true)
  const closeDeleteDialog = () => setShowDeleteDialog(false)

  const handleExportXls = () => {
    exportXls(
      `${process.env.REACT_APP_REST_BASE_URL}/event/export`,
      'event-export.xlsx',
      KeycloakHelper.getToken(keycloak)
    )
  }

  const getDialog = () => {
    return (
      <S.Dialog
        aria-label="Import Events"
        isOpen={showDialog}
        onDismiss={close}
      >
        <S.CloseButton className="close-button" onClick={close}>
          x
        </S.CloseButton>
        <S.DialogTitle>Import events</S.DialogTitle>
        <DropzoneArea
          maxFileSize={100000000}
          filesLimit={1}
          onChange={(files) => {
            setFile(files[0])
          }}
        ></DropzoneArea>
        <S.DialogText>
          Any current products that have the same SKU will not be overwritten.
        </S.DialogText>
        {uploadErrors.length > 0 && (
          <>
            <S.DialogError>
              There are errors with your submission. SKUs with errors will not
              be imported.
            </S.DialogError>
            {uploadErrors.map((uploadError) => {
              return (
                <S.DialogError>{`Line ${uploadError.line}: ${uploadError.message}`}</S.DialogError>
              )
            })}
          </>
        )}
        <S.DialogText>
          <S.DialogLink
            href="https://theindustrious.atlassian.net/wiki/external/2081161241/ZmY2ZDdhZmI2YjQyNDBjZDljYjhiMDVkMjMyOTY5ZTk?atlOrigin=eyJpIjoiY2UyM2M0ZGEyNThhNDA4OTg0NDg1OGY0MzdiODI2MDgiLCJwIjoiYyJ9"
            target="blank"
          >
            <Icon icon="BooksVertical" size={18} />
            Instructions (for reference)
          </S.DialogLink>
        </S.DialogText>
        <S.ButtonRow>
          <S.CancelButton
            label={`Cancel`}
            colorOption="stroke"
            onClick={close}
            disabled={isUploading}
          />
          <S.UploadButton
            disabled={isUploading}
            label={isUploading ? `Uploading...` : `Upload and continue`}
            onClick={() => handleUploadFile(file)}
          />
        </S.ButtonRow>
        {isUploading && <PageSpinner />}
      </S.Dialog>
    )
  }

  const getDeleteDialog = () => {
    return (
      <S.Dialog
        aria-label="Bulk Delete Events"
        isOpen={showDeleteDialog}
        onDismiss={closeDeleteDialog}
      >
        <S.CloseButton className="close-button" onClick={closeDeleteDialog}>
          x
        </S.CloseButton>
        <S.DialogTitle>Bulk Delete Events</S.DialogTitle>
        <DropzoneArea
          maxFileSize={100000000}
          filesLimit={1}
          onChange={(files) => {
            setFile(files[0])
          }}
        ></DropzoneArea>
        <S.DialogText>
          Please upload an Excel file (.xlsx) with one worksheet titled "Sheet1"
          which contains the word "SKU" in cell A1 and a list of SKUs, one per
          row, in column A
        </S.DialogText>
        <S.ButtonRow>
          <S.CancelButton
            label={`Cancel`}
            colorOption="stroke"
            onClick={closeDeleteDialog}
            disabled={isUploading}
          />
          <S.UploadButton
            disabled={isUploading}
            label={isUploading ? `Uploading...` : `Upload and continue`}
            onClick={() => handleUploadDeleteFile(file)}
          />
        </S.ButtonRow>
        {isUploading && <PageSpinner />}
      </S.Dialog>
    )
  }

  return (
    <>
      <S.Main>
        <SectionEvents />
        <S.PageActions>
          <TabList
            tabKey="status"
            tabs={[
              { description: 'All', tabName: 'all' },
              { description: 'Active', tabName: 'active' },
              { description: 'Draft', tabName: 'draft' },
              { description: 'Archived', tabName: 'archived' },
              { description: 'Expired', tabName: 'expired' },
              { description: 'Hidden', tabName: 'hidden' },
            ]}
          />
          <S.ActionButtonsContainer>
            <ExportXlsLink onClick={handleExportXls} />
            <ImportLink onClick={open} />
            <BulkDeleteLink onClick={openDeleteDialog} />
            <S.AddEventButton
              label={t('section_events.button_text')}
              colorOption="blue"
              onClick={handleAddEvent}
            />
          </S.ActionButtonsContainer>
        </S.PageActions>
        <S.Separator />
        <S.SearchFiltersContainer>
          <S.Search
            placeholder={t('section_events_list.input_placeholder')}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
          <PaginationDetails
            totalItems={pagination.totalCount}
            totalPages={pagination.totalPages}
            itemsPerPage={pagination.pageSize}
            pageNumber={pagination.page}
            onClickPrev={handlePrevPage}
            onClickNext={handleNextPage}
          />
        </S.SearchFiltersContainer>
        <SectionEventsList loading={loading} events={events} />
        <S.PaginationContainer>
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </S.PaginationContainer>
      </S.Main>
      {getDialog()}
      {getDeleteDialog()}
    </>
  )
}

export default Events
