import { useCallback } from 'react'
import * as S from './styles'

type ActionButtonVariant = 'primary' | 'secondary' | 'secondaryLight'

export interface ActionButtonProps {
  isDisabled?: boolean
  isLoading?: boolean
  children?: React.ReactNode
  variant?: ActionButtonVariant
  onClick?: () => void
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const handleOnClick = useCallback(() => {
    if (props.isDisabled || !props.onClick) {
      return
    }

    props.onClick()
  }, [props.isDisabled, props.onClick])

  return (
    <S.ActionButton
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      variant={props.variant}
      onClick={handleOnClick}
    >
      {props.children}
    </S.ActionButton>
  )
}

export default ActionButton
