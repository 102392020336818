import { ReactNode, useEffect, useState } from 'react'
import * as S from './styles'
import Separator from '../../atoms/Separator'

const getProperInitialTabValue = (tabs: Tab[], initialTabValue?: string) => {
  if (
    initialTabValue &&
    initialTabValue.length !== 0 &&
    tabs.some((x) => x.value === initialTabValue)
  ) {
    return initialTabValue
  }

  return tabs[0].value
}

export interface Tab {
  label: string
  value: string
  content: ReactNode
}

export interface TabsProps {
  tabs: Tab[]
  initialTabValue?: string
  onTabChanged?: (tabValue: string) => void
  rightContent?: ReactNode
}

const Tabs: React.FC<TabsProps> = (props) => {
  const tabListClasses = S.useTabListStyles()
  const tabClasses = S.useTabStyles()
  const tabPanelClasses = S.useTabPanelStyles()

  const [value, setValue] = useState<string>(
    getProperInitialTabValue(props.tabs, props.initialTabValue)
  )

  useEffect(() => {
    if (!props.onTabChanged) {
      return
    }

    props.onTabChanged(value)
  }, [value])

  const handleChange = (newValue: string) => {
    setValue(newValue)
  }

  return (
    <S.Box>
      <S.TabContext value={value}>
        <S.TabsWithRightContentBox>
          <S.TabList
            classes={{ indicator: tabListClasses.indicator }}
            onChange={(_, newValue) => handleChange(newValue)}
          >
            {props.tabs.map((x) => (
              <S.Tab
                classes={{ root: tabClasses.root }}
                label={x.label}
                value={x.value}
              />
            ))}
          </S.TabList>
          {!!props.rightContent && (
            <S.RightContentBox>{props.rightContent}</S.RightContentBox>
          )}
        </S.TabsWithRightContentBox>
        <Separator />
        {props.tabs.map((x) => (
          <S.TabPanel classes={{ root: tabPanelClasses.root }} value={x.value}>
            {x.content}
          </S.TabPanel>
        ))}
      </S.TabContext>
    </S.Box>
  )
}

export default Tabs
