import styled from 'styled-components'

import { Box as MuiBox  } from '@material-ui/core'


export const Box = MuiBox

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`

export const SpinnerBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
`
