import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { ReportProps } from '../../../../pages/ViewReport'
import {
  ActivityByDealerReport,
  ActivityByDealerRow,
} from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import moment from 'moment'

const ActivityByDealer: React.FC<ReportProps> = ({
  channel,
  dealer,
  teamMember,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ActivityByDealerReport | null>(null)
  const formatter = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }, [])
  useEffect(() => {
    http
      .get<ActivityByDealerReport>({
        url: `/reports/activityByDealer?channel=${channel}&dealer=${dealer}&teamMember=${teamMember}&dateFrom=${encodeURIComponent(
          moment(startDate).startOf('day').toISOString(true)
        )}&dateTo=${encodeURIComponent(
          moment(endDate).endOf('day').toISOString(true)
        )}`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, dealer, endDate, startDate, teamMember])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportDataEvents = report.events.events.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          Agent: reportRow.agent,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataProducts = report.products.products.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          Agent: reportRow.agent,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataMedia = report.media.products.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          Agent: reportRow.agent,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataMicrosites = report.microsites.products.map(
        (reportRow) => {
          return {
            Name: reportRow.name,
            Type: reportRow.type,
            'Media Id': reportRow.mediaId,
            Channel: reportRow.channel,
            'Order Number': reportRow.orderNumber,
            Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
            'Dealer Id': reportRow.dealerId,
            'Team Member': reportRow.teamMember,
            Agent: reportRow.agent,
            'Co-op': reportRow.coop,
            Spend: reportRow.spend,
            Total: reportRow.total,
          }
        }
      )
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const eventsWs = XLSX.utils.json_to_sheet(reportDataEvents)
      const productsWs = XLSX.utils.json_to_sheet(reportDataProducts)
      const mediaWs = XLSX.utils.json_to_sheet(reportDataMedia)
      const micrositesWs = XLSX.utils.json_to_sheet(reportDataMicrosites)
      const wb = {
        Sheets: {
          Events: eventsWs,
          'Ad Materials': productsWs,
          Media: mediaWs,
          Microsites: micrositesWs,
        },
        SheetNames: ['Events', 'Ad Materials', 'Media', 'Microsites'],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ActivityByDealer${fileExtension}`)
    }
  }, [report])
  if (!report || report === null) {
    return <PageSpinner />
  }
  console.log(JSON.stringify(report))
  return (
    <S.Container>
      <S.ReportTitle>
        Activity by Dealer
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Dealer Name</S.FilterListItemTitle>
          <S.FilterListItemValue>{dealer}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Team Members</S.FilterListItemTitle>
          <S.FilterListItemValue>{teamMember}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Events</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.events.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Events: ${report.events.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Ad Materials</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.products.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Ad Materials: ${report.products.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Media</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.media.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Media: ${report.media.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Microsites</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.microsites.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Microsites: ${report.microsites.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
      </S.SummaryList>
      {report.events.events.length > 0 && (
        <>
          <S.ReportTitle>Events</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Event Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Agent</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.events.events.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`events${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{item.agent}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
        </>
      )}
      {report.products.products.length > 0 && (
        <>
          <S.ReportTitle>Ad Materials</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Agent</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.products.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`products${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{item.agent}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
        </>
      )}
      {report.media.products.length > 0 && (
        <>
          <S.ReportTitle>Media</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Agent</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.media.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`media${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{item.agent}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
        </>
      )}
      {report.microsites.products.length > 0 && (
        <>
          <S.ReportTitle>Microsites</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Event Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Agent</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.microsites.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`microsites${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{item.agent}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ActivityByDealer
