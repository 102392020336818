import React, { useEffect, useState, useCallback } from 'react'
import { DealerEngagementRow } from '@monorepo/interfaces'
import { UserHelper, http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import moment from 'moment'

interface DealerEngagementProps {
  startDate: Date
  endDate: Date
  channel?: string
}

const DealerEngagement: React.FC<DealerEngagementProps> = ({
  startDate,
  endDate,
  channel,
}) => {
  const [report, setReport] = useState<DealerEngagementRow[] | null>(null)

  useEffect(() => {
    http
      .get<DealerEngagementRow[]>({
        url: `/reports/dealerEngagement?dateFrom=${encodeURIComponent(
          moment(startDate).startOf('day').toISOString(true)
        )}&dateTo=${encodeURIComponent(
          moment(endDate).endOf('day').toISOString(true)
        )}${!!channel ? `&channel=${channel}` : ''}`,
      })
      .then(({ data }) => {
        setReport(data)
        console.log(JSON.stringify(data))
      })
  }, [endDate, startDate])

  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportData = report.map((item) => ({
        Dealer: UserHelper.getFullUserName(item.firstName, item.lastName),
        Email: item.email,
        MasterDealerID: item.masterDealerId,
        Products: item.products,
        Events: item.events,
        Resources: item.resources,
        Checkouts: item.checkouts,
        DirectvDemos: item.directvDemos,
      }))
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData)
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `DealerEngagement${fileExtension}`)
    }
  }, [report])

  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Dealer Engagement
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {channel?.toUpperCase()}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      {report.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Dealer</S.TableHeadCell>
                  <S.TableHeadCell>Email</S.TableHeadCell>
                  <S.TableHeadCell>Master Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Products</S.TableHeadCell>
                  <S.TableHeadCell>Events</S.TableHeadCell>
                  <S.TableHeadCell>Resources</S.TableHeadCell>
                  <S.TableHeadCell>Checkouts</S.TableHeadCell>
                  <S.TableHeadCell>Directv Demos</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.map((item: DealerEngagementRow, index: number) => (
                  <S.TableRow key={`row${index}`}>
                    <S.TableCell>
                      {UserHelper.getFullUserName(
                        item.firstName,
                        item.lastName
                      )}
                    </S.TableCell>
                    <S.TableCell>{item.email}</S.TableCell>
                    <S.TableCell>{item.masterDealerId}</S.TableCell>
                    <S.TableCell>{item.products}</S.TableCell>
                    <S.TableCell>{item.events}</S.TableCell>
                    <S.TableCell>{item.resources}</S.TableCell>
                    <S.TableCell>{item.checkouts}</S.TableCell>
                    <S.TableCell>{item.directvDemos}</S.TableCell>
                  </S.TableRow>
                ))}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default DealerEngagement
