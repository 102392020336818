import { useEffect, useState } from 'react'
import * as S from './styles'

import {
  Product,
  ProductPropsData,
  ProductStatus,
  ProductVariant,
} from '@monorepo/interfaces'

import { Spinner, Icon } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AxiosResponse } from 'axios'
import {
  ProductHelper,
  ReactSelectOptionType,
  formatAxiosError,
  http,
  isObject,
} from '@monorepo/infra'

export interface SectionProductsListProps extends ProductPropsData {
  loading: boolean
  isFeaturedProducts?: boolean
}

const inlineEditingProductSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  categories: Yup.array().of(Yup.string()).optional(),
  expirationDate: Yup.date().nullable().optional(),
  tags: Yup.array().of(Yup.string()).optional(),
  status: Yup.number().required('Status is required'),
  sku: Yup.string()
    .required('SKU is required')
    .test(
      'checkDupSKU',
      'An item with this SKU already exists',
      function (value, context) {
        return new Promise((resolve, reject) => {
          http
            .get<AxiosResponse>({
              url: `/product?sku=${value}`,
            })
            .then((response) => {
              if (
                Array.isArray(response.data.data) &&
                response.data.data.filter(
                  (item: Product) =>
                    item._id != context.parent._id && item.sku == value
                ).length
              ) {
                resolve(false)
              }

              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
})

const inlineEditingProductInitialValues = {
  title: '',
  categories: [] as string[],
  expirationDate: null as Date | null | undefined,
  tags: [] as string[],
  status: ProductStatus.DRAFT,
  sku: '',
  _id: undefined as string | undefined,
}

const SectionProductsList: React.FC<SectionProductsListProps> = ({
  products,
  loading = true,
  isFeaturedProducts,
}) => {
  const [featuredChannels, setFeaturedChannels] = useState<
    string[] | null | undefined
  >()
  const [activeFeaturedChannel, setActiveFeaturedChannel] = useState<
    string | null | undefined
  >()
  const [activeFeaturedChannelProducts, setActiveFeaturedChannelProducts] =
    useState<Product[] | null | undefined>()
  const featuredChannelViewAll = 'view all'
  const [inlineEditingItem, setInlineEditingItem] = useState<
    Product | undefined
  >(undefined)
  const [tagsOptions, setTagsOptions] = useState<ReactSelectOptionType[]>([])
  const [saving, setSaving] = useState(false)
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
  const [savingError, setSavingError] = useState<string | undefined>(undefined)

  const { errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: inlineEditingProductSchema,
    initialValues: inlineEditingProductInitialValues,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        console.log(values)
      })
    },
  })

  const sx = {
    '& .MuiInputBase-root': {
      'font-family': 'PFDINText',
      'font-size': '14px',
      'font-style': 'normal',
      'font-weight': '400',
      'line-height': '16px',
    },
  }

  useEffect(() => {
    http
      .get({ url: '/product/tags' })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          setTagsOptions(
            response?.data.map((item) => ({ value: item, label: item }))
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (!isFeaturedProducts) {
      setFeaturedChannels(undefined)
      setActiveFeaturedChannel(undefined)
      setActiveFeaturedChannelProducts(undefined)
      return
    }

    if (loading || !Array.isArray(products) || !products.length) {
      return
    }

    const channels = ProductHelper.getChannels(products)

    if (Array.isArray(channels) && channels.length) {
      channels.push(featuredChannelViewAll)
      handleFeaturedChannelButtonClick(featuredChannelViewAll)
    }

    setFeaturedChannels(channels)
  }, [isFeaturedProducts, loading, products])

  const getVariantNames = (variants: ProductVariant[]): string => {
    const variantNames = variants.map((variant) =>
      ProductHelper.getVariantTitle(variant)
    )
    return variantNames.join(', ')
  }
  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleEditProduct = (index: number) => {
    const id = products[index]._id
    history.push(`/products/edit/${id}`)
  }

  const handleFeaturedChannelButtonClick = (channel?: string) => {
    setActiveFeaturedChannel(channel)
    setActiveFeaturedChannelProducts(
      !!channel && channel !== featuredChannelViewAll
        ? products?.filter(
            (product) =>
              Array.isArray(product?.channels) &&
              product?.channels.findIndex(
                (item) => item?.toLowerCase() === channel.toLowerCase()
              ) !== -1
          )
        : products
    )
  }

  const handleInlineEditClick = (item?: Product) => {
    if (!item) {
      return
    }

    setInlineEditingItem(item)
    formik.setValues({
      title: item.title,
      categories: item.categories,
      expirationDate: item.expirationDate,
      tags: item.tags,
      status: item.status,
      sku: item.sku,
      _id: item._id,
    })
  }

  const handleInlineEditSaveClick = async () => {
    if (!formik.isValid) {
      return
    }

    const changedItem = {
      ..._.cloneDeep(inlineEditingItem),
      ...formik.values,
    } as Product

    if (ProductHelper.isProductEqual(inlineEditingItem, changedItem)) {
      setInlineEditingItem(undefined)
      return
    }

    const savedItem = (await saveItem(changedItem)) as Product

    if (Array.isArray(products) && isObject(savedItem)) {
      const idx = products.findIndex((item) => item._id === savedItem._id)
      if (idx !== -1) {
        products[idx] = savedItem
      }
    }

    setInlineEditingItem(undefined)
  }

  const saveItem = async (item: Product) => {
    setSaving(true)
    setSavingError(undefined)

    let savedItem: Product | undefined = undefined

    try {
      const response = await http.patch({
        url: `/product/${item._id}`,
        data: item,
      })

      if (response.status === 200 && isObject(response.data)) {
        savedItem = response.data as Product
      }
    } catch (error) {
      setSavingError(formatAxiosError(error))
      setIsAlertDialogOpen(true)
    } finally {
      setSaving(false)
    }

    return savedItem
  }

  const handleInlineEditCancelClick = () => {
    setInlineEditingItem(undefined)
  }

  return (
    <S.Container>
      {isFeaturedProducts &&
      Array.isArray(featuredChannels) &&
      featuredChannels.length ? (
        <S.FeaturedChannelContainer>
          {featuredChannels.map((item) => (
            <S.FeaturedChannelButton
              key={item}
              data-active={activeFeaturedChannel === item}
              onClick={() => {
                handleFeaturedChannelButtonClick(item)
              }}
            >
              {item}
            </S.FeaturedChannelButton>
          ))}
        </S.FeaturedChannelContainer>
      ) : undefined}
      <S.TableContainer>
        <S.Table aria-label="Products Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>
                {t('section_products_list.table_title_1')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_2')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_7')}
              </S.TableHeadCell>
              <S.TableHeadCell></S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          <TableBody>
            {loading || saving ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              (isFeaturedProducts
                ? activeFeaturedChannelProducts
                : products
              )?.map((product: Product, index: number) =>
                inlineEditingItem?._id === product._id ? (
                  <S.TbodyRow key={index}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <S.TBodyCell scope="row">
                        <TextField
                          id="title"
                          name="title"
                          label="Title"
                          required
                          value={formik.values.title}
                          onChange={({ target: { value } }) =>
                            formik.setFieldValue('title', value)
                          }
                          error={!!errors.title && touched.title}
                          helperText={errors.title}
                          sx={sx}
                        />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <TextField
                          id="sku"
                          name="sku"
                          label="SKU"
                          required
                          value={formik.values.sku}
                          onChange={({ target: { value } }) =>
                            formik.setFieldValue('sku', value)
                          }
                          error={!!errors.sku && touched.sku}
                          helperText={errors.sku}
                          sx={sx}
                        />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row" colSpan={2}>
                        <CreatableSelect
                          id="categories"
                          name="categories"
                          placeholder="DIRECTV, AT&T"
                          isMulti
                          options={[]}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                          }}
                          value={formik.values.categories.map((category) => ({
                            value: category,
                            label: category,
                          }))}
                          onChange={(newValue) =>
                            formik.setFieldValue(
                              'categories',
                              newValue.map(({ value }) => value)
                            )
                          }
                          styles={S.MultiSelectStyle}
                        />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <S.KeyboardDateTimePicker
                          id="expirationDate"
                          name="expirationDate"
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Select a date & time"
                          format="MM/dd/yy hh:mm a"
                          value={formik.values.expirationDate ?? null}
                          onChange={(date) =>
                            formik.setFieldValue('expirationDate', date)
                          }
                          ampm
                        />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <CreatableSelect
                          id="tags"
                          name="tags"
                          placeholder="Bestseller, Featured"
                          isMulti
                          options={tagsOptions}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                          }}
                          value={formik.values.tags.map((tag) => ({
                            value: tag,
                            label: tag,
                          }))}
                          onChange={(newValue) =>
                            formik.setFieldValue(
                              'tags',
                              newValue.map(({ value }) => value)
                            )
                          }
                          styles={S.MultiSelectStyle}
                        />
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <TextField
                          id="status"
                          name="status"
                          select
                          label="Status"
                          value={formik.values.status.toString()}
                          defaultValue={ProductStatus.DRAFT.toString()}
                          onChange={({ target: { value } }) =>
                            formik.setFieldValue('status', parseInt(value))
                          }
                          sx={sx}
                        >
                          {ProductHelper.productStatusOptions.map(
                            (option: ReactSelectOptionType) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </S.TBodyCell>
                      <S.TBodyCell scope="row">
                        <IconButton
                          aria-label="save"
                          title="Save"
                          onClick={handleInlineEditSaveClick}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          title="Cancel"
                          onClick={handleInlineEditCancelClick}
                        >
                          <CancelIcon />
                        </IconButton>
                      </S.TBodyCell>
                    </MuiPickersUtilsProvider>
                  </S.TbodyRow>
                ) : (
                  <S.TbodyRow
                    key={index}
                    onClick={() => handleEditProduct(index)}
                  >
                    <S.TBodyCell scope="row">
                      <S.ProductImageContainer>
                        {product.variants
                          ?.slice(0, 1)
                          ?.shift()
                          ?.media?.slice(0, 3)
                          ?.map((src, index) => (
                            <S.ProductImage
                              key={index}
                              src={src}
                              alt={product.title}
                            />
                          ))}
                      </S.ProductImageContainer>
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      <S.ProductTitle>{product.title}</S.ProductTitle>
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {product.categories[0]}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {getVariantNames(product.variants)}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {product.expirationDate
                        ? format(new Date(product.expirationDate), 'MM/dd/yyyy')
                        : 'N/D'}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">{product.tags}</S.TBodyCell>
                    <S.TBodyCell scope="row">
                      <S.TagsContainer>
                        {!product.expirationDate ||
                        new Date(product?.expirationDate) > new Date() ? (
                          <S.StatusTag data-status={product.status}>
                            {ProductHelper.getStatus(product.status)}
                          </S.StatusTag>
                        ) : null}
                        {product.expirationDate &&
                        new Date(product?.expirationDate) < new Date() ? (
                          <S.StatusTag data-status={5}>Expired</S.StatusTag>
                        ) : null}
                      </S.TagsContainer>
                    </S.TBodyCell>
                    <S.TBodyCell
                      scope="row"
                      title="Inline Edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleInlineEditClick(product)
                      }}
                    >
                      <Icon icon="Pencil2" size={24} />
                    </S.TBodyCell>
                  </S.TbodyRow>
                )
              )
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
      <Dialog
        open={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {savingError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAlertDialogOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  )
}

export default SectionProductsList
