import styled from 'styled-components'

import { Box as MuiBox } from '@material-ui/core'

export const Box = MuiBox

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`
