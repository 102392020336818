import * as S from './styles'
import SectionFileTransfer from '../../organisms/SectionFileTranfer'
import { useCallback, useMemo } from 'react'
import Tabs from '../../molecules/Tabs'
import ActionButton from '../../atoms/ActionButton'
import { useHistory, useLocation } from 'react-router-dom'
import { History } from 'history'
import SentSharedFilesComponent from './SentSharedFilesComponent'
import ReceivedSharedFilesComponent from './ReceivedSharedFilesComponent'
import UnreadSharedFilesComponent from './UnreadSharedFilesComponent'

const navigateToSendFile = (history: History) => {
  history.push('/admin/file-transfer/send')
}

const tabs = [
  {
    label: 'UNREAD',
    value: 'unread',
    content: (
      <S.TabContentBox>
        <UnreadSharedFilesComponent />
      </S.TabContentBox>
    ),
  },
  {
    label: 'RECEIVED',
    value: 'received',
    content: (
      <S.TabContentBox>
        <ReceivedSharedFilesComponent />
      </S.TabContentBox>
    ),
  },
  {
    label: 'SENT',
    value: 'sent',
    content: (
      <S.TabContentBox>
        <SentSharedFilesComponent />
      </S.TabContentBox>
    ),
  },
]

const FileTransferComponent: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const initialTabValue = useMemo(() => query.get('tab'), [query])

  const handleSendFileClick = useCallback(
    () => navigateToSendFile(history),
    [history]
  )

  const handleTabChanged = useCallback(
    (newTabValue: string) => {
      const newQueryParams = new URLSearchParams(location.search)
      newQueryParams.set('tab', newTabValue)
      history.push({ search: newQueryParams.toString() })
    },
    [history, location.search]
  )

  const rightContent = useMemo(
    () => (
      <>
        <ActionButton onClick={handleSendFileClick}>SEND A FILE</ActionButton>
      </>
    ),
    [handleSendFileClick]
  )

  return (
    <>
      <S.MainBox>
        <SectionFileTransfer />
        <Tabs
          tabs={tabs}
          initialTabValue={initialTabValue ?? ''}
          onTabChanged={handleTabChanged}
          rightContent={rightContent}
        />
      </S.MainBox>
    </>
  )
}

export default FileTransferComponent
