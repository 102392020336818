import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = MuiBox

export const OuterBox = styled(Box)<{ outerColor?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.outerColor ? props.outerColor : '#D9D9D9')};
`

export const InnerBox = styled(Box)<{ innerColor?: string; widthPercent: number }>`
  width: ${(props) => props.widthPercent}%;
  height: 100%;
  background-color: ${(props) => (props.innerColor ? props.innerColor : '#337ACE')};
  transition: width 0.3s ease-in-out;
`
