import styled, { css } from 'styled-components'

type ActionButtonVariant = 'primary' | 'secondary' | 'secondaryLight'

const primaryStyle = css`
  background-color: #337ace;
  color: #ffffff;
  border: 0.1rem solid #337ace;
`

const primaryDisabledStyle = css`
  background-color: #65a9fc;
  color: #ffffff;
  border: 0.1rem solid #65a9fc;
`

const secondaryStyle = css`
  background-color: #000000;
  color: #ffffff;
  border: 0.1rem solid #000000;
`

const secondaryDisabledStyle = css`
  background-color: #cccccc;
  color: #ffffff;
  border: 0.1rem solid #cccccc;
`

const secondaryLightStyle = css`
  background-color: #ffffff;
  color: #000000;
  border: 0.1rem solid #000000;
`

const secondaryLightDisabledStyle = css`
  background-color: #999999;
  color: #000000;
  border: 0.1rem solid #000000;
`

const getActionButtonVariantStyle = (variant?: ActionButtonVariant, isDisabled?: boolean) => {
  if (!variant) {
    return isDisabled ? primaryDisabledStyle : primaryStyle
  }

  switch (variant) {
    case 'secondary':
      return isDisabled ? secondaryDisabledStyle : secondaryStyle
    case 'secondaryLight':
      return isDisabled ? secondaryLightDisabledStyle : secondaryLightStyle
    case 'primary':
      return isDisabled ? primaryDisabledStyle : primaryStyle
  }
}

export const ActionButton = styled.button<{
  isDisabled?: boolean
  isLoading?: boolean
  variant?: ActionButtonVariant
}>`
  ${(props) => getActionButtonVariantStyle(props.variant, props.isDisabled)}
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1rem;
  padding: 0.8rem 1.6rem;
  cursor: ${(props) =>
    props.isDisabled ? 'not-allowed' : props.isLoading ? 'wait' : 'pointer'};
`
