import { http, ProductHelper } from '@monorepo/infra'
import { Product, Vendor } from '@monorepo/interfaces'
import { AxiosResponse } from 'axios'
import * as Yup from 'yup'

import { productStatus } from '../../../../database'

export const ProductSchema = Yup.object().shape({
  status: Yup.number().required('Status is required'),
  type: Yup.number().required('Type is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  mediaIdACB: Yup.string().required('Media ID is required'),
  tags: Yup.array().of(Yup.string()).optional(),
  categories: Yup.array().of(Yup.string()).optional(),
  sku: Yup.string()
    .required('SKU is required')
    .test(
      'checkDupSKU',
      'An item with this SKU already exists',
      function (value, context) {
        return new Promise((resolve, reject) => {
          http
            .get<AxiosResponse>({
              url: `/product?sku=${value}`,
            })
            .then((response) => {
              if (
                Array.isArray(response.data.data) &&
                response.data.data.filter(
                  (item: Product) =>
                    item._id != context.parent._id && item.sku == value
                ).length
              ) {
                resolve(false)
              }

              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
  endDate: Yup.date().nullable().optional(),
  activationDate: Yup.date().nullable().optional(),
  expirationDate: Yup.date().nullable().optional(),
  searchTerms: Yup.array().of(Yup.string()).optional(),
  channels: Yup.array().of(Yup.string()).optional(),
  dealerTypes: Yup.array().of(Yup.string()).optional(),
  notes: Yup.string().optional(),
  media: Yup.array().of(Yup.string()),
  vendor: Yup.number().required('Required'),
  deliveryMethods: Yup.array()
    .of(Yup.number())
    .min(1, 'You must select at least 1 delivery method'),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().optional().nullable(),
        printAndShipOptions: Yup.array().of(
          Yup.object({
            pieceCount: Yup.number().required('Count is a required field'),
            price: Yup.string().required(),
          })
        ),
        // .min(1, 'You must enter at least 1 print and ship option'),
        media: Yup.array().of(Yup.string()).optional(),
        size: Yup.number().min(1).required('Required'),
        inDesignFile: Yup.string().optional().nullable(),
        downloadFile: Yup.string().optional().nullable(),
      })
    )
    .min(1, 'You must enter at least 1 product variant')
    .required('Required'),
})

export const initialValues = {
  title: '',
  description: '',
  status: parseInt(productStatus[0]?.value),
  vendor: Vendor.PURE,
  mediaIdACB: '',
  type: parseInt(ProductHelper.getProductTypeOptions()[0]?.value),
  tags: [],
  categories: [],
  sku: '',
  deliveryMethods: [],
  activationDate: null,
  expirationDate: null,
  searchTerms: [],
  channels: [],
  dealerTypes: [],
  notes: '',
  variants: [],
  variantsForm: [],
}
